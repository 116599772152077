import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={81}
      height={33}
      viewBox="0 0 81 33"
      {...props}
    >
      <g
        data-name="Rectangle 178"
        fill="#f0a202"
        stroke="#f1a202"
        strokeWidth={1}
      >
        <path d="M8 0h33v33H8a8 8 0 01-8-8V8a8 8 0 018-8z" stroke="none" />
        <path
          d="M8 .5h32a.5.5 0 01.5.5v31a.5.5 0 01-.5.5H8A7.5 7.5 0 01.5 25V8A7.5 7.5 0 018 .5z"
          fill="none"
        />
      </g>
      <g data-name="Rectangle 179" fill="none" stroke="#f0a202" strokeWidth={1}>
        <path
          d="M0 0h33a8 8 0 018 8v17a8 8 0 01-8 8H0V0z"
          stroke="none"
          transform="translate(40)"
        />
        <path
          d="M1 .5h32A7.5 7.5 0 0140.5 8v17a7.5 7.5 0 01-7.5 7.5H1a.5.5 0 01-.5-.5V1A.5.5 0 011 .5z"
          transform="translate(40)"
        />
      </g>
      <text
        transform="translate(8.009 7.916)"
        fill="#fff"
        fontSize={13}
        fontFamily="Montserrat-Bold, Montserrat"
        fontWeight={700}
      >
        <tspan x={5.501} y={13}>
          {"ID"}
        </tspan>
      </text>
      <text
        transform="translate(47.556 7.533)"
        fill="#f0a202"
        fontSize={13}
        fontFamily="Montserrat-Bold, Montserrat"
        fontWeight={700}
      >
        <tspan x={3.613} y={13}>
          {"EN"}
        </tspan>
      </text>
    </svg>
  );
}

export default SvgComponent;
