import React from "react";
import ExampleLogo from "./ExampleLogo";
import A1 from "../../../images/Logos/Clients/1GovInst/1.svg";
import A2 from "../../../images/Logos/Clients/1GovInst/2.svg";
import A3 from "../../../images/Logos/Clients/1GovInst/3.svg";
import A4 from "../../../images/Logos/Clients/1GovInst/4.svg";
import A5 from "../../../images/Logos/Clients/1GovInst/5.png";
import A6 from "../../../images/Logos/Clients/1GovInst/6.svg";
import A7 from "../../../images/Logos/Clients/1GovInst/7.png";
import A8 from "../../../images/Logos/Clients/1GovInst/8.svg";
import A9 from "../../../images/Logos/Clients/1GovInst/9.png";
import A10 from "../../../images/Logos/Clients/1GovInst/10.png";
import A11 from "../../../images/Logos/Clients/1GovInst/11.svg";
import A12 from "../../../images/Logos/Clients/1GovInst/12.svg";

import B1 from "../../../images/Logos/Clients/2BankGov/1.svg";
import B2 from "../../../images/Logos/Clients/2BankGov/2.png";
import B3 from "../../../images/Logos/Clients/2BankGov/3.svg";
import B4 from "../../../images/Logos/Clients/2BankGov/4.png";
import B5 from "../../../images/Logos/Clients/2BankGov/5.svg";
import B6 from "../../../images/Logos/Clients/2BankGov/6.svg";
import B7 from "../../../images/Logos/Clients/2BankGov/7.svg";
import B8 from "../../../images/Logos/Clients/2BankGov/8.svg";
import B9 from "../../../images/Logos/Clients/2BankGov/9.svg";
import B10 from "../../../images/Logos/Clients/2BankGov/10.svg";
import B11 from "../../../images/Logos/Clients/2BankGov/11.svg";

import C1 from "../../../images/Logos/Clients/3BankPriv/1.svg";
import C2 from "../../../images/Logos/Clients/3BankPriv/2.svg";
import C3 from "../../../images/Logos/Clients/3BankPriv/3.svg";
import C4 from "../../../images/Logos/Clients/3BankPriv/4.svg";
import C5 from "../../../images/Logos/Clients/3BankPriv/5.svg";
import C6 from "../../../images/Logos/Clients/3BankPriv/6.png";
import C7 from "../../../images/Logos/Clients/3BankPriv/7.svg";
import C8 from "../../../images/Logos/Clients/3BankPriv/8.png";
import C9 from "../../../images/Logos/Clients/3BankPriv/9.png";
import C10 from "../../../images/Logos/Clients/3BankPriv/10.png";
import C11 from "../../../images/Logos/Clients/3BankPriv/11.jpg";
import C12 from "../../../images/Logos/Clients/3BankPriv/12.png";
import C13 from "../../../images/Logos/Clients/3BankPriv/13.svg";
import C14 from "../../../images/Logos/Clients/3BankPriv/14.svg";

import D1 from "../../../images/Logos/Clients/4BankInt/1.svg";
import D2 from "../../../images/Logos/Clients/4BankInt/2.svg";
import D3 from "../../../images/Logos/Clients/4BankInt/3.svg";
import D4 from "../../../images/Logos/Clients/4BankInt/4.svg";
import D5 from "../../../images/Logos/Clients/4BankInt/5.svg";
import D6 from "../../../images/Logos/Clients/4BankInt/6.png";
import D7 from "../../../images/Logos/Clients/4BankInt/7.svg";
import D8 from "../../../images/Logos/Clients/4BankInt/8.png";

import E1 from "../../../images/Logos/Clients/5FinInst/1.png";
import E2 from "../../../images/Logos/Clients/5FinInst/2.png";
import E3 from "../../../images/Logos/Clients/5FinInst/3.png";
import E4 from "../../../images/Logos/Clients/5FinInst/4.png";
import E5 from "../../../images/Logos/Clients/5FinInst/5.svg";
import E6 from "../../../images/Logos/Clients/5FinInst/6.png";
import E7 from "../../../images/Logos/Clients/5FinInst/7.png";
import E8 from "../../../images/Logos/Clients/5FinInst/8.svg";
import E9 from "../../../images/Logos/Clients/5FinInst/9.svg";
import E10 from "../../../images/Logos/Clients/5FinInst/10.png";
import E11 from "../../../images/Logos/Clients/5FinInst/11.jpg";
import E12 from "../../../images/Logos/Clients/5FinInst/12.png";
import E13 from "../../../images/Logos/Clients/5FinInst/13.png";
import E14 from "../../../images/Logos/Clients/5FinInst/14.png";
import E15 from "../../../images/Logos/Clients/5FinInst/15.gif";
import E16 from "../../../images/Logos/Clients/5FinInst/16.png";
import E17 from "../../../images/Logos/Clients/5FinInst/17.png";
import E18 from "../../../images/Logos/Clients/5FinInst/18.png";
import E19 from "../../../images/Logos/Clients/5FinInst/19.jpg";
import E20 from "../../../images/Logos/Clients/5FinInst/20.jpg";
import E21 from "../../../images/Logos/Clients/5FinInst/21.jpg";
import E22 from "../../../images/Logos/Clients/5FinInst/22.png";
import E23 from "../../../images/Logos/Clients/5FinInst/23.png";
import E24 from "../../../images/Logos/Clients/5FinInst/24.png";
import E25 from "../../../images/Logos/Clients/5FinInst/25.png";
import E26 from "../../../images/Logos/Clients/5FinInst/26.png";
import E27 from "../../../images/Logos/Clients/5FinInst/27.png";
import E28 from "../../../images/Logos/Clients/5FinInst/28.png";
import E29 from "../../../images/Logos/Clients/5FinInst/29.png";
import E30 from "../../../images/Logos/Clients/5FinInst/30.png";

import F1 from "../../../images/Logos/Clients/6EMoney/1.svg";
import F2 from "../../../images/Logos/Clients/6EMoney/2.svg";
import F3 from "../../../images/Logos/Clients/6EMoney/3.png";
import F4 from "../../../images/Logos/Clients/6EMoney/4.png";
import F5 from "../../../images/Logos/Clients/6EMoney/5.png";
import F6 from "../../../images/Logos/Clients/6EMoney/6.svg";
import F7 from "../../../images/Logos/Clients/6EMoney/7.svg";
import F8 from "../../../images/Logos/Clients/6EMoney/8.svg";
import F9 from "../../../images/Logos/Clients/6EMoney/9.svg";
import F10 from "../../../images/Logos/Clients/6EMoney/10.jpeg";
import F11 from "../../../images/Logos/Clients/6EMoney/11.png";
import F12 from "../../../images/Logos/Clients/6EMoney/12.png";
import F13 from "../../../images/Logos/Clients/6EMoney/13.png";
import F14 from "../../../images/Logos/Clients/6EMoney/14.svg";
import F15 from "../../../images/Logos/Clients/6EMoney/15.png";
import F16 from "../../../images/Logos/Clients/6EMoney/16.jpg";

import G1 from "../../../images/Logos/Clients/7Transport/1.svg";
import G2 from "../../../images/Logos/Clients/7Transport/2.svg";

import H1 from "../../../images/Logos/Clients/8Telecom/1.png";
import H2 from "../../../images/Logos/Clients/8Telecom/2.svg";
import H3 from "../../../images/Logos/Clients/8Telecom/3.svg";
import H4 from "../../../images/Logos/Clients/8Telecom/4.svg";
import H5 from "../../../images/Logos/Clients/8Telecom/5.svg";
import H6 from "../../../images/Logos/Clients/8Telecom/6.png";
import H7 from "../../../images/Logos/Clients/8Telecom/7.svg";
import H8 from "../../../images/Logos/Clients/8Telecom/8.svg";
import H9 from "../../../images/Logos/Clients/8Telecom/9.png";
import H10 from "../../../images/Logos/Clients/8Telecom/10.svg";
import H11 from "../../../images/Logos/Clients/8Telecom/11.png";
import H12 from "../../../images/Logos/Clients/8Telecom/12.svg";
import H13 from "../../../images/Logos/Clients/8Telecom/13.svg";
import H14 from "../../../images/Logos/Clients/8Telecom/14.svg";
import H15 from "../../../images/Logos/Clients/8Telecom/15.svg";
import H16 from "../../../images/Logos/Clients/8Telecom/16.svg";
import H17 from "../../../images/Logos/Clients/8Telecom/17.png";

import I1 from "../../../images/Logos/Clients/9Insurance/1.png";
import I2 from "../../../images/Logos/Clients/9Insurance/2.png";

import J1 from "../../../images/Logos/Clients/10RealEstate/1.svg";
import J2 from "../../../images/Logos/Clients/10RealEstate/2.png";
import J3 from "../../../images/Logos/Clients/10RealEstate/3.jpg";
import J4 from "../../../images/Logos/Clients/10RealEstate/4.png";

import K1 from "../../../images/Logos/Clients/11Education/1.png";
import K2 from "../../../images/Logos/Clients/11Education/2.png";
import K3 from "../../../images/Logos/Clients/11Education/3.png";
import K4 from "../../../images/Logos/Clients/11Education/4.png";
import K5 from "../../../images/Logos/Clients/11Education/5.png";
import K6 from "../../../images/Logos/Clients/11Education/6.png";
import K7 from "../../../images/Logos/Clients/11Education/7.png";
import K8 from "../../../images/Logos/Clients/11Education/8.png";
import K9 from "../../../images/Logos/Clients/11Education/9.png";
import K10 from "../../../images/Logos/Clients/11Education/10.png";
import K11 from "../../../images/Logos/Clients/11Education/11.png";

export const exampleLogos = [
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
  <ExampleLogo />,
];

function Logo({ src, maxHeight = 100, contentContainerStyle }) {
  if (contentContainerStyle) {
    return (
      <div
        style={{
          maxHeight,
          minWidth: 100,
          maxWidth: 200,
          width: "10vw",
          margin: "2em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={src}
          style={{
            maxHeight,
            minWidth: 100,
            maxWidth: 200,
            objectFit: "contain",
          }}
          alt="logo"
        />
      </div>
    );
  }

  return (
    <img
      src={src}
      style={{
        maxHeight,
        minWidth: 100,
        maxWidth: 200,
        width: "10vw",
        margin: "2em",
      }}
      alt="logo"
    />
  );
}

// GOVERNMENT INSTITUTIONS
// 1	PLN
// 2	BPJS Kesehatan
// 3	Pegadaian
// 4	Pos
// 5	PDAM
// 6	PBB DKI
// 7	PBB Depok
// 8	PBB Sumut
// 9	PGN
// 10	IPC
// 11	Telkom
// 12	Telkomsel

export const governmentLogos = [
  <Logo src={A1} />,
  <Logo src={A2} />,
  <Logo src={A3} />,
  <Logo src={A4} />,
  <Logo src={A5} />,
  <Logo src={A6} />,
  <Logo src={A7} />,
  <Logo src={A8} />,
  <Logo src={A9} />,
  <Logo src={A10} />,
  <Logo src={A11} />,
  <Logo src={A12} />,
];

// BANKING - GOVERNMENT
// 1	Mandiri
// 2	BRI
// 3	BNI
// 4	BTN
// 5	Bank DKI
// 6	Bank Jabar Banten
// 7	Bank Sumut
// 8	Bank Jateng
// 9	BPD DYI
// 10	BPD Bali
// 11	Bank Nagari

export const bankingGovLogos = [
  <Logo src={B1} />,
  <Logo src={B2} />,
  <Logo src={B3} />,
  <Logo src={B4} />,
  <Logo src={B5} />,
  <Logo src={B6} />,
  <Logo src={B7} />,
  <Logo src={B8} />,
  <Logo src={B9} />,
  <Logo src={B10} />,
  // <Logo src={B11} />,
];

// BANKING - PRIVATE
// 1	BCA
// 2	Bank Danamon
// 3	Bank Permata
// 4	Bank Muamalat
// 5	Bank Panin
// 6	Bank Mayora
// 7	Bank Maspion
// 8	MNC Bank
// 9	Bank Sinarmas
// 10	Bank Mega
// 11	Bank INA
// 12	Bank Ganesha
// 13	Bank Bukopin
// 14	Bank Victoria

export const bankingPrivateLogos = [
  <Logo src={C1} />,
  <Logo src={C2} />,
  <Logo src={C3} />,
  <Logo src={C4} />,
  <Logo src={C5} />,
  <Logo src={C6} />,
  <Logo src={C7} />,
  <Logo src={C8} />,
  <Logo src={C9} />,
  <Logo src={C10} />,
  <Logo src={C11} />,
  <Logo src={C12} />,
  <Logo src={C13} />,
  <Logo src={C14} />,
];

// BANKING - INTERNATIONAL
// 1	Citibank
// 2	HSBC
// 3	SCB
// 4	MUFG
// 5	UOB
// 6	OCBC NISP
// 7	CIMB Niaga
// 8	Maybank

export const bankingInternationalLogos = [
  <Logo src={D1} />,
  <Logo src={D2} />,
  <Logo src={D3} />,
  <Logo src={D4} />,
  <Logo src={D5} />,
  <Logo src={D6} />,
  <Logo src={D7} />,
  <Logo src={D8} />,
];

// FINANCIAL INSTITUTIONS
// 1	FIF
// 2	OTO
// 3	SOF
// 4	BAF
// 5	ACC
// 6	WOM
// 7	Finansia
// 8	Aeon
// 9	SFI
// 10	JTO Finance
// 11	Indomobil
// 12	MPM Finance
// 13	Artha Asia
// 14	Multindo
// 15	Buana Finance
// 16	Artha Prima (APF)
// 17	Procar
// 18	Buana Sejahtera Multidana
// 19	Hasjrat Multifinance
// 20	Bhakti Idola Tama (BIT)
// 21	Bina Artha (BAV)
// 22	Verena
// 23	SMS Finance
// 24	Clipan Finance
// 25	Radana Finance
// 26	Bima Finance
// 27	NSC Finance
// 28	Alijarah Finance
// 29	Smart Finance
// 30	Trihamas Finance
// 31	Trihamas Syariah

export const financialInstitutionLogos = [
  <Logo src={E1} />,
  <Logo src={E2} />,
  <Logo src={E3} />,
  <Logo src={E4} />,
  <Logo src={E5} />,
  <Logo src={E6} />,
  <Logo src={E7} />,
  <Logo src={E8} />,
  <Logo src={E9} />,
  <Logo src={E10} />,
  <Logo src={E11} />,
  <Logo src={E12} />,
  <Logo src={E13} />,
  <Logo src={E14} />,
  <Logo src={E15} />,
  <Logo src={E16} />,
  <Logo src={E17} />,
  <Logo src={E18} />,
  <Logo src={E19} />,
  <Logo src={E20} />,
  <Logo src={E21} />,
  <Logo src={E22} />,
  <Logo src={E23} />,
  <Logo src={E24} />,
  <Logo src={E25} />,
  <Logo src={E26} />,
  <Logo src={E27} />,
  <Logo src={E28} />,
  <Logo src={E29} />,
  <Logo src={E30} />,
];

// E-COMMERCE, E-WALLET & E-MONEY
// 1	Tokopedia
// 2	Shopee
// 3	Bukalapak
// 4	Blibli
// 5	JD.ID
// 6	OVO
// 7	Gopay
// 8	Dana
// 9	LinkAja
// 10	UK
// 11	Flip
// 12	TrueMoney
// 13	OY!
// 14	Akulaku
// 15	Kaspro
// 16	Paypro

export const eCommerceLogos = [
  <Logo src={F1} />,
  <Logo src={F2} />,
  <Logo src={F3} />,
  <Logo src={F4} />,
  <Logo src={F5} />,
  <Logo src={F6} />,
  <Logo src={F7} />,
  <Logo src={F8} />,
  <Logo src={F9} />,
  <Logo src={F10} />,
  <Logo src={F11} />,
  <Logo src={F12} />,
  <Logo src={F13} />,
  // <Logo src={F14} />,
  <Logo src={F15} />,
  <Logo src={F16} />,
];

// TRANSPORTATION
// 1	Grab
// 2	Citilink

export const transportationLogos = [<Logo src={G1} />, <Logo src={G2} />];

// TELECOMMUNICATION & ENTERTAINMENT
// 1	Google Play
// 2	Spotify
// 3	Youtube Premium
// 4	MNC Vision
// 5	MNC Play
// 6	Kvision
// 7	MyRepublic
// 8	First Media
// 9	Indihome
// 10	Transvision
// 11	Metrasat
// 12	XL
// 13	Indosat
// 14	Smartfren
// 15	Net1
// 16	Biznet
// 17	CBN

export const telecomEntertainmentLogos = [
  <Logo src={H1} />,
  <Logo src={H2} />,
  <Logo src={H3} />,
  <Logo src={H4} />,
  <Logo src={H5} />,
  <Logo src={H6} />,
  <Logo src={H7} />,
  <Logo src={H8} />,
  <Logo src={H9} />,
  <Logo src={H10} />,
  <Logo src={H11} />,
  <Logo src={H12} />,
  <Logo src={H13} />,
  <Logo src={H14} />,
  // <Logo src={H15} />,
  <Logo src={H16} />,
  <Logo src={H17} />,
];

// INSURANCE
// 1	Sequis
// 2	ACA

export const insuranceLogos = [<Logo src={I1} />, <Logo src={I2} />];

// REAL ESTATE
// 1	Lippo Karawachi
// 2	Lippo Cikarang
// 3	St Moritz
// 4	Kemang Village
// 5	TMD Depok
// 6	TMD Fairview
// 7	TMD Hillcrest

export const realEstateLogos = [
  <Logo src={J1} />,
  <Logo src={J2} />,
  <Logo src={J3} />,
  <Logo src={J4} />,
];

// EDUCATION
// 1	Unesa
// 2	UPH
// 3	Universitas Airlangga
// 4	ITS
// 5	MDP School
// 6	UGM
// 7	ITB
// 8	Universitas Medan Area (UMA)
// 9	UINSA
// 10	Unpad
// 11	UMM

export const educationLogos = [
  <Logo src={K1} contentContainerStyle />,
  <Logo src={K2} contentContainerStyle />,
  <Logo src={K3} contentContainerStyle />,
  <Logo src={K4} contentContainerStyle />,
  // <Logo src={K5} contentContainerStyle />,
  <Logo src={K6} contentContainerStyle />,
  <Logo src={K7} contentContainerStyle />,
  <Logo src={K8} contentContainerStyle />,
  <Logo src={K9} contentContainerStyle />,
  <Logo src={K10} contentContainerStyle />,
  <Logo src={K11} contentContainerStyle />,
];
