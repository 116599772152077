import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});

export default function LandingPanel({
  children,
  background,
  style,
  height = "400px",
}) {
  const classes = useStyles();

  return (
    <section
      className={classes.root}
      style={{
        backgroundImage: `url(${background})`,
        height,
        ...style,
      }}
    >
      {children}
    </section>
  );
}
