import { Grid , useMediaQuery} from "@mui/material";
import React from "react";
import Button from "../../Button/Button";
import "./ContactForm.css";
import { useTheme } from "@mui/material/styles";



function FormLabel(props) {
  return <label htmlFor={props.htmlFor}>{props.title}</label>;
}

export default function ContactForm() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = (e, message) => {
    e.preventDefault();

    // do form submission here
   
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
    <form className="react-form" style={{paddingLeft: matches ? 80 : 0 , paddingRight: matches ? 80 : 0, paddingBottom : matches ? 80 : 30}}  onSubmit={handleSubmit}>
    <div className="flex-row" style={{margin: matches ? "unset" : 30}}>
        <fieldset className="form-group">
          <FormLabel htmlFor="formFirstName" title="Nama Depan" />
          <input
            id="formFirstName"
            className="form-input"
            name="name"
            type="text"
            required
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </fieldset>
        <fieldset className="form-group">
          <FormLabel htmlFor="formLastName" title="Nama Belakang" />
          <input
            id="formLastName"
            className="form-input"
            name="name"
            type="text"
            required
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </fieldset>
      </div>
      <div className="flex-row" style={{margin: matches ? "unset" : 30}}>
      <fieldset className="form-group">
        <FormLabel htmlFor="formEmail" title="Email" />
        <input
          id="formEmail"
          className="form-input"
          name="email"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </fieldset>
      </div>
      <div className="flex-row" style={{margin: matches ? "unset" : 30}}>
      <fieldset className="form-group">
        <FormLabel htmlFor="formSubject" title="Telepon" />
        <input
          id="formSubject"
          className="form-input"
          name="phone"
          type="text"
          required
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </fieldset>
      </div>
      <div className="flex-row" style={{margin: matches ? "unset" : 30, marginBottom : 0}}>
      <fieldset className="form-group">
        <FormLabel htmlFor="formMessage" title="Pesan" />
        <input
          id="formMessage"
          className="form-input"
          name="message"
          required
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        ></input>
      </fieldset>
      </div>
      <div className="flex-row" style={{margin: matches ? "unset" : 30, marginTop: 0, marginBottom: 45}}>
      <fieldset className="form-group flex-center">
        <Button
          type="submit"
          width={160}
          style={{
            backgroundColor: "#F0A202",
            color: "#FFFFFF",
            textTransform: "uppercase",
            fontSize: 22,
          }}
        >
          Kirim
        </Button>
      </fieldset>
      </div>
    </form>
    </Grid>
  );
}
