import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ?500 : "unset"}>
      <>{matches ? <Heading color="#FFFFFF">Ruang Pres</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Ruang Pres</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
            Pengumuman terbaru, wawasan industri dan perspektif dari SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}> Pengumuman terbaru, wawasan industri dan perspektif dari SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48
            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Jadi Pengendali Bank Bisnis, Kredivo Suntik Rp 439,69 Miliar
            </Heading> :  <p style={{color:"#1B297C", margin: 0,marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>Jadi Pengendali Bank Bisnis, Kredivo Suntik Rp 439,69 Miliar</p> }</>
            <>{matches ?<Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
              21 Oktober 2021
            </Heading> :  <p style={{fontSize:"5vw", margin: 0,marginBottom: 30 ,color: "#444444",fontWeight: 500 }} >21 Oktober 2021</p> }</>
            <div className={classes.textSectionWrapper}>
              <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
                PT FinAccel Teknologi Indonesia, pengelola financial technology (fintech) peer to peer lending Kredivo mengucurkan dana Rp 439,69 miliar untuk menambah 484,24 juta saham atau 16% saham di PT Bank Bisnis Internasional Tbk (BBSI). 
                Dengan demikian, Kredivo kini menjadi pemilik saham mayoritas di Bank Bisnis dengan porsi kepemilikan sebanyak 40% atau setara 1,21 miliar lembar saham. 
                Kredivo melakukan pembelian di harga Rp 908 per saham pada 15 Oktober 2021 yang mana harga saham BBSI kala itu ditutup di Rp 6.050 per saham.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
            “Tujuan transaksi untuk investasi,” ucap Direktur Utama FinAccel Teknologi Indonesia Jules Dennis Rolf Lerchl dalam keterangan resmi, Kamis (21/10/2021).
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
            Kredivo dengan jajaran dewan komisaris Ketua Umum Kamar Dagang dan Industri (Kadin) Indonesia Arsjad Rasjid, mantan Menteri Koordinator (Menko) Bidang Perekonomian dan mantan Gubernur Bank Indonesia Darmin Nasution serta mantan diplomat AS Karen Brooks, 
            sejak 21 Mei 2021 merupakan pemegang saham baru di Bank Bisnis dengan memegang 24% saham. 
            Kala itu, perseroan membeli 726,36 juta lembar saham di harga Rp 759 per saham. 
            Dana yang digelontorkan Kredivo senilai Rp 551,31 miliar.
            </Text>
            
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
            Adapun, bertambahnya kepemilikan Kredivo di bank ini sekaligus menggerus kepemilikan pemegang saham lainnya. 
            Tercatat sebelumnya Sundjono Suriadi merupakan pemegang saham pengendali dengan kepemilikan 25%. 
            Saat ini kepemilikannya tersisa sebesar 20%. Lalu kepemilikan PT Sun Land Investama yang sebelumnya 19,74% saham di Bank Bisnis saat ini tersisa 13,20%. 
            Terakhir PT Sun Antarnusa Investment yang sebelumnya mengempit 14,94% saham hanya tinggal 10,50%. 
            Sedangkan kepemilikan publik di saham ini masih bertahan di posisi 16,30%.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
            Di lain pihak, Bank Bisnis berencana untuk melakukan penerbitan saham baru dengan skema memberikan hak memesan efek terlebih dahulu (HMETD) atau rights issue untuk yang kedua kalinya (Penawaran Umum Terbatas/PUT II). 
            Rencananya PUT akan menerbitkan sebanyak-banyaknya 434.782.609 saham atau setara 14,37% dari modal disetor perseroan. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
            "Saham-saham yang akan dikeluarkan oleh perseroan adalah saham atas nama dengan nilai nominal yang sama dengan nilai nominal saham-saham perseroan yang telah dikeluarkan, yaitu Rp 100 per saham," tulis manajemen Bank Bisnis.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
            Aksi korporasi ini dilakukan untuk pemenuhan modal inti minimum dan akan dilaksanakan pada akhir semester II 2021. 

            Sementara platform kredit digital Kredivo berencana meluncurkan bank digital bernama Lime. 
            Ini menandai masuknya Kredivo ke bisnis neobank di pasar terbesar Asia Tenggara. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
            Lime direncanakan fokus pada produk tabungan dan kredit yang ramah milenial.
            Rencana peluncuran neobank ini akan membuka peluang pertumbuhan baru bagi Kredivo, yang pendapatannya saat ini fokus pada bisnis kartu kredit dan pinjaman individu. 

            Di sisi kinerja, masuknya Kredivo ke Bank Bisnis berhasil mendongkrak kinerja bank tersebut. Bank Bisnis Internasional mencatatkan laba tahun berjalan setelah pajak bersih sebesar Rp 29,86 miliar hingga 30 Juni 2021. 
            Perolehan tersebut tumbuh 130,15% dari periode yang sama tahun lalu sebesar Rp 12,97 miliar.
            </Text>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0}}>
            Kredit yang diberikan tercatat sebesar Rp 953,19 miliar per 30 Juni 2021, naik 4,68% dari posisi akhir tahun lalu sebesar Rp 910,60 miliar. 
            Sementara itu penghimpunan dana pihak ketiga tumbuh 28,285, dari posisi akhir Desember 2020 sebesar Rp 411,59 miliar menjadi Rp 528,01 miliar per 30 Juni 2021. Adapun total aset per 30 Juni 2021 sebesar Rp 1,60 triliun, tumbuh 10,89% dari posisi akhir tahun lalu sebesar Rp 1,44 triliun            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
