import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ?500 : "unset"}>
        <>{matches ? <Heading color="#FFFFFF">Ruang Pres</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Ruang Pres</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
            Pengumuman terbaru, wawasan industri dan perspektif dari SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}> Pengumuman terbaru, wawasan industri dan perspektif dari SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48
            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Sah! Akulaku Jack Ma Resmi Jadi Pengendali Bank Neo
            </Heading> : <p style={{color:"#1B297C", margin: 0,marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>Sah! Akulaku Jack Ma Resmi Jadi Pengendali Bank Neo</p>}</>
            <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
              08 Oktober 2021
            </Heading> :  <p style={{fontSize:"5vw", margin: 0,marginBottom: 30 ,color: "#444444",fontWeight: 500 }} >08 Oktober 2021</p>}</>
            <div className={classes.textSectionWrapper}>
              <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
                Perusahaan fintech PT Akulaku Silvrr Indonesia yang disokong Grup Alibaba milik crazy rich China, Jack Ma, resmi menjadi pengendali emiten bank PT Bank Neo Commerce Tbk (BNC).
                Hal ini disetujui dalam Rapat Umum Pemegang Saham Luar Biasa (RUPSLB) yang kedua untuk mengesahkan PT Akulaku Silvrr Indonesia sebagai pengendali BNC.
                Penetapan ini telah sesuai dengan peraturan perundang-undangan tentang pengambilalihan yang diatur dalam POJK No.41/POJK.03/2019 tentang Penggabungan, Peleburan, Pengambilalihan, Integrasi dan Konversi Bank Umum.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
            Direktur Utama Bank Neo Commerce, Tjandra Gunawan mengatakan, dengan pengesahan Akulaku menjadi pemegang saham pengendali di BNC, dia meyakini, kolaborasi ini akan semakin intensif dan erat juga akan menjadi sebuah kekuatan tersendiri yang menghadirkan berbagai inovasi berkelanjutan.

            Salah satu kolaborasi yang semakin intensif adalah dalam penyaluran dana ke masyarakat. 
            Pengalaman Akulaku dalam sisi lending menjadi nilai tambah bagi BNC sebagai bank digital sejalan dengan upaya BNC untuk memperkuat sisi lending di akhir tahun ini hingga tahun mendatang.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
            "Dukungan dari Akulaku ini semakin memperkuat ekosistem digital BNC dan membuat BNC memiliki proposisi yang berbeda dibandingkan dengan pelaku industri lainnya," kata Tjandra, dalam keterangan resmi, Jumat (8/10/2021).
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",margin: 0,marginBottom: 30 }}>
            Saat ini porsi kepemilikan PT Akulaku Silvrr Indonesia menjadi sebesar 24,98%, PT Gozco Capital Indonesia sebesar 16,53%, Yellow Brick Enterprise Ltd sebesar 11,10%, Rockcore Financial Technology Co. Ltd sebesar 6,12% dan masyarakat sebesar 41,27%.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0,marginBottom: 30 }}>
              PT Akulaku Silvrr Indonesia merupakan bagian dari Akulaku Group yang menyediakan layanan di bidang e-commerce dan keuangan digital terkemuka di Asia Tenggara yang beroperasi di empat negara besar: Indonesia, Filipina, Vietnam, dan Malaysia. Akulaku juga disuntik oleh Ant Financial, perusahaan yang terafiliasi dengan pendiri Alibaba, Jack Ma.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: 0}}>
            Fintech ini menargetkan pasar negara berkembang dengan kelompok konsumen yang kurang terlayani tetapi berkredibilitas dengan pertumbuhan cepat.            
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
