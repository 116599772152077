import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFFFFF",
    height: "100px",
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "fixed",
    zIndex: 99999999,
  },
  spacer: {
    height: "100px",
    width: "100vw",
  },
});

export default function NavBar({ children }) {
  const classes = useStyles();

  return (
    <>
      <nav className={classes.root}>{children}</nav>
      <div className={classes.spacer} />
    </>
  );
}
