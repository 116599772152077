import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFFFFF",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #707070",
  },
  text: {
    color: "#989696",
    fontWeight: "bold",
    display: "flex",
    margin: 100,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#989696",
  },
});

export default function Footer({ language }) {
  const classes = useStyles();

  const spacer = <div style={{ width: 30 }} />;

  return (
    <footer className={classes.root}>
      <a
        className={clsx([classes.text, classes.link])}
        href="https://www.linkedin.com/company/syb-online/"
      >
        {language == "en" ? "Follow Us" : "Ikuti Kami"}
      </a>
      <p className={classes.text}>
        © PT Sarana Yukti Bandhana
        {spacer}
        <span style={{ fontWeight: "lighter" }}>|</span>
        {spacer}
        <Link className={classes.link} to={`/${language}/contact`}>
          {language == "en" ? "Contact" : "Kontak"}
        </Link>
      </p>
    </footer>
  );
}
