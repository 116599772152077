import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Pressroom</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Pressroom</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
             The latest announcements, industry insights, and perspectives from
            SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}>  The latest announcements, industry insights, and perspectives from
            SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48

            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Becoming Bank Bisnis Controller, Kredivo injects Rp 439.69 billion
            </Heading> : <p style={{color:"#1B297C",margin: 0 , marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>Becoming Bank Bisnis Controller, Kredivo injects Rp 439.69 billion
            </p>}</>
            <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
            21 October 2021
            </Heading> : <p style={{fontSize:"5vw",  margin:  0,marginBottom:  30 ,color: "#444444",fontWeight: 500 }} >21 October 2021</p>}</>
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
                PT FinAccel Teknologi Indonesia, the manager of peer to peer lending financial technology (fintech) Kredivo disbursed Rp 439.69 billion to add 484.24 million shares or 16% shares in PT Bank Bisnis Internasional Tbk (BBSI). 
                Thus, Kredivo is now the majority shareholder in Bank Bisnis with 40% ownership portion or equivalent of 1.21 billion shares. 
                Kredivo made the purchase at the price of Rp 908 per share on 15 October 2021, where BBSI’s share price at that time closed at Rp 6,050 per share.

                </Text>
              </div>
            </div>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            “The purpose of the transaction is for investment,” said President Director of FinAccel Teknologi Indonesia, Jules Dennis Rolf Lerchl in an official statement, Thursday (21/10/2021).
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
              Kredivo with the Board of Commissioners Chairman of the Indonesian Chamber of Commerce and Industry (Kadin) Arsjad Rasjid, former Coordinating Minister (Menko) for the Economy and former Governor of Bank Indonesia Darmin Nasution and former US diplomat Karen Brooks, since 21 May 2021 are the new shareholders in Bank Bisnis holding 24% shares. 
              At that time, the company bought 726.36 million shares at the price of Rp 759 per share. 
              The funds disbursed by Kredivo amounted to Rp 551.31 billion.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
              Meanwhile, the increase in Kredivo’s ownership in this bank has eroded ownership of other shareholders. 
              Previously, Sundjono Suriadi was the controlling shareholder with 25% ownership. 
              Currently, the remaining ownership is 20%. Then the ownership of PT Sun Land Investama, which previously had 19.74% shares in Bank Bisnis, currently has 13.20% remaining. Lastly, PT Sun Antarnusa Investment, which previously held 14.94% shares, only had 10.50%. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
              Meanwhile, public ownership in this stock still stands at 16.30%.
              On the other hand, Bank Bisnis plans to issue new shares with a scheme to provide pre-emptive rights (HMETD) or rights issue for the second time (Limited Public Offering/PUT II). 
              The plan is that the PUT will issue a maximum will issue a maximum of 434,782,609 shares or 14.37% of the company’s paid-in capital. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
              “The shares that will be issued by the company are shares with nominal value equal to nominal value of company’s issued shares, which is Rp 100 per share.” wrote by the management of Bank Bisnis.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            This corporate action is carried out to fulfil the minimum core capital and will be carried out at the end of second semester of 2021.
            Meanwhile, the digital credit platform Kredivo plans to launch a digital bank called Lime. This marks Kredivo’s entry into the neobank business in Southeast Asia’s largest market. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            Lime is planned to focus on millennial-friendly savings and credit products. The planned launch of this neobank will open up new growth opportunities for Kredivo, whose current revenue focus Is on credit card and individual loan business.
            On the performance side, Kredivo’s entry to Bank Bisnis succeeded in boosting bank’s performance. Bank Bisnis Internasional records a profit for the year after net tax of Rp 29.86 billion as of 30 June 2021. This revenue grew by 130.15% from the same period last year of Rp 12.97 billion.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw",margin: matches ? "unset" : 0}}>
            Loans disbursed were recorded at Rp 953.19 billion as of 30 June 2021, increase of 4.68% from 2020 Rp 910.60 billion. 
            Meanwhile the collection of third-party funds grew by 28.28% at the end of December 2020 Rp 411.59 billion to Rp 528.01 billion as of 30 June 2021. 
            The total assets as of 30 June 2021 amounted to Rp 1.6 trillion, growing 10.89% from 2020 Rp 1.44 trillion.
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
