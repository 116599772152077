import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    display: "block",
    position: "relative",
    overflow: "hidden",
    textTransform: "uppercase",
    letterSpacing: "0.15rem",
    lineHeight: 1.8,
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "bold",
    color: "#000026",
    padding: 16,
    flexShrink: 0,
  },
  root: {
    position: "relative",
  },
  menu: {
    position: "absolute",
    width: 208,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.2s linear, visibility 0s linear 0.2s",
    background: "#FFFFFF",
    zIndex: 99999,
  },
  visible: {
    opacity: 1,
    visibility: "visible",
    transition: "opacity 0.2s linear, visibility 0s linear",
  },
});

export default function NavBarDropdown({ title, children }) {
  const [show, setShow] = React.useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root} onMouseLeave={() => setShow(false)}>
      <Link className={classes.link} onMouseOver={() => setShow(true)}>
        {title}
      </Link>
      <div className={clsx(classes.menu, { [classes.visible]: show })}>
        {children}
      </div>
    </div>
  );
}
