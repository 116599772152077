import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={81} height={33} {...props}>
      <g data-name="Group 80">
        <g data-name="Group 79">
          <g data-name="Rectangle 178" fill="none" stroke="#f1a202">
            <path d="M8 0h33v33H8a8 8 0 01-8-8V8a8 8 0 018-8z" stroke="none" />
            <path d="M8 .5h32a.5.5 0 01.5.5v31a.5.5 0 01-.5.5H8A7.5 7.5 0 01.5 25V8A7.5 7.5 0 018 .5z" />
          </g>
          <path
            data-name="Rectangle 179"
            d="M41 0h33a8 8 0 018 8v17a8 8 0 01-8 8H41V0z"
            fill="#f0a202"
          />
          <text
            transform="translate(8.009 7.916)"
            fill="#f0a202"
            fontSize={13}
            fontFamily="Montserrat-Bold, Montserrat"
            fontWeight={700}
          >
            <tspan x={5.501} y={13}>
              {"ID"}
            </tspan>
          </text>
          <text
            transform="translate(47.556 7.532)"
            fill="#fff"
            fontSize={13}
            fontFamily="Montserrat-Bold, Montserrat"
            fontWeight={700}
          >
            <tspan x={3.613} y={13}>
              {"EN"}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
