import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Pressroom</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Pressroom</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
             The latest announcements, industry insights, and perspectives from
            SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}>  The latest announcements, industry insights, and perspectives from
            SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection} >
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48

            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Akulaku Jack Ma officially becomes the controller of Bank Neo
            </Heading> : <p style={{color:"#1B297C",margin: 0 , marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>Akulaku Jack Ma officially becomes the controller of Bank Neo
          </p>}</>
          <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
          08 October 2021
            </Heading> : <p style={{fontSize:"5vw" ,color: "#444444",fontWeight: 500,margin: 0 , marginBottom: 30 }} > 08 October 2021</p>}</>
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
                Fintech company PT Akulaku Silvrr Indonesia, backed by Alibaba Group owned by crazy rich China, Jack Ma, has officially become the controller of issuer bank PT Bank Neo Commerce Tbk (BNC).
                This was approved in the second Extraordinary General Meeting of Shareholders to ratify PT Akulaku Silvrr Indonesia as controller of BNC.
                This determination is in accordance with the laws and regulations regarding takeovers regulated in POJK No. 41/POJK.03/2019 concerning Merger, Consolidation, Takeover, Integration and Conversion of Commercial Banks.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            President Director of Bank Neo Commerce, Tjandra Gunawan said, with the approval of Akulaku as the controlling shareholder in BNC, he believes that this collaboration will be more intensive and closer as a separate force that will bring further various innovations.
            One of the more intensive collaborations is the distribution of funds to the public. Akulaku’s experience in the lending side is an additional value for BNC as a digital bank in line with BNC’s effort to strengthen its lending side at the end of 2021 until next year.
            </Text>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Currently the ownership portion of PT Akulaku Silvrr Indonesia is 24.98%, PT Gozco Capital Indonesia is 16.53%, Yellow Brick Enterprise Ltd is 11.10%, Rockcore Financial Technology Co. Ltd is 6.12% and the public is 41.27%.
            </Text>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            PT Akulaku Silvrr Indonesia is part of the Akulaku Group, which provides services in the leading e-commerce and digital finance sector in Southeast Asia with operations in four major countries: Indonesia, the Philippines, Vietnam and Malaysia. Akulaku was also injected by Ant Financial, a company affiliated with Alibaba founder Jack Ma.
            </Text>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0}}>
            This fintech targets emerging markets with underserved but credible consumer groups with fast growth.
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
