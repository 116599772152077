import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import background1 from "../../images/Home/Carousel/HomeQuote/1.webp";
import background2 from "../../images/Home/Carousel/HomeQuote/2.webp";
import background3 from "../../images/Home/Carousel/HomeQuote/3.webp";
import background4 from "../../images/Home/Carousel/HomeQuote/4.webp";
import background5 from "../../images/Home/Carousel/HomeQuote/5.webp";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../Text/Text";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";


const quotes = [
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “Peranan SYB sebagai switching dan agregator pembayaran sangat
      menguntungkan terhadap perusahaan kami dalam hal sinergi dan kerja sama
      dengan perusahaan-perusahaan fintek dan bank-bank di seluruh Indonesia,
      karena SYB telah memungkinkan ekspansi dari jaringan pembayaran kami
      diberdayakan dengan sangat efektif dan efisien”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Teguh Budi Ismanto, Senior Executive Vice President of PT. Pegadaian
      (Persero)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “World Bank mencatat bahwa PT Perusahaan Listrik Negara (Persero) (PLN)
      perlu untuk mempercepat siklus Piutang ... Akan sangat berarti jika
      Piutang PLN dapat dipangkas [dan] dipersingkat. Dengan ide yang dipelopori
      dan dirintis oleh SYB, hal ini menjadi sangat memungkinkan.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – M. Mochtar Wirjosaputro, selama masa jabatan sebagai Direktur
      Pengembangan Usaha, Direktorat Jenderal Pengembangan Kelistrikan dan
      Energi (1989-1993) (Kutipan dari autobiografi berjudul “Nrimo Ing Pandum”)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      Kerja sama dengan SYB dimulai sejak 2004, dan saat ini SYB sebagai salah
      satu Partner Strategis Grup OTO dalam mengembangkan jasa pembayaran
      digital … Bersama SYB, Grup OTO terus mengembangkan jaringan pembayaran
      digital/ online, dan telah berhasil meningkatkan rasio dari pembayaran
      non-tunai. Akhirnya, selama era mandemi pada akhir 2020, Grup OTO bersama
      dengan SYB, berhasil menerapkan system pembayaran digital GoTagihan,
      dimana transaksi terus bertumbuh secara eksponensial.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Kemal Fajar, Chief Information Officer dari PT Summit Oto Finance dan PT
      Oto Multiartha
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      "Pada saat ini kerja sama IMFI dengan SYB telah berjalan lebih dari 10
      tahun. PT. SYB telah membantu kami dalam mengembangkan serangkaian saluran
      pembayaran non-tunai untuk peminjam kami untuk membayar cicilan mereka.
      Inovasi dan solusi yang diusulkan untuk memenuhi kebutuhan peminjam kami
      telah membantu menopang perkembangan IMFI. Jasa SYB juga telah memberikan
      kemudahan untuk peminjam kami dari banyak cabang kami yang tersebar di
      seluruh area di Indonesia. Dukungan SYB dalam memajukan bisnis kami dan
      kesigapan tim mereka dalam menangani permasalahan yang muncul, telah
      mendapatkan persetujuan kami. Kami mengharapkan kesuksesan yang
      berkesinambungan untuk SYB di masa mendatang.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Sifra Viona Tjahjono, Director PT Indomobil Finance Indonesia (IMFI)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “SYB telah memungkinkan kami untuk mengembangkan jasa pembayaran cicilan
      di beragam marketplace, yang aman dan dapat dipercaya.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Felix Priscellius, Head of the IT Division at PT Wahana Ottomitra
      Multiartha Tbk
    </Text>
  </div>,
];

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "rgba(0,0,0,0.6)",
    height: 750,
    position: "absolute",
    left: "10vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "50px",
  },
  carouselSlide1: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide2: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide3: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide4: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background4})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide5: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background5})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainSection: {
    backgroundColor: "#00004D",
    margin: 0,
    color: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textLarge: {
    fontFamily: "Roboto",
    fontSize: 80,
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    margin: 0,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
  },
});

function HomeQuoteCarousel({ height, transitionTime = 1500, interval = 6000 }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  const indicatorStyles = {
    display: "inline-block",
    marginRight: "10px",
    color: "#fff",
    cursor: "pointer",
    height: 10,
    width: 10,
    zIndex: 10,
    // marginRight: 8,
    borderRadius: "50%",
    "-webkit-transition": "background-color 0.6s ease",
    transition: "background-color 0.6s ease",
    border: "2px solid #141413",
    transform: matches ? "translateX(200px) translateY(-50px)" : "translateX(100px) translateY(-97px)",
  };

  const imageClasses = [
    classes.carouselSlide1,
    classes.carouselSlide2,
    classes.carouselSlide3,
    classes.carouselSlide4,
    classes.carouselSlide5,
  ];



  return (
    <Carousel
      autoPlay
      swipeable={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      showIndicators={true}
      transitionTime={transitionTime}
      interval={interval}
      renderIndicator={(onClickHandler, isSelected, index, label) => (
        <li
          style={{
            ...indicatorStyles,
            background: isSelected ? "#141413" : "transparent",
            outline: isSelected ? "1px solid #141413" : "none",
            
          }}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
          
        />
      )}
    >
      {quotes.map((quote, index) => (
        <>{matches ? 
        <div style={{ width: "100vw", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <section className={imageClasses[index]} style={{ height }} />
           </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#FFFFFF",
                margin: 0,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <div style={{ width: matches ? 500 : 300, height: matches ? "unset" : 655, textAlign: matches ? "unset" : "left", color: "#1B297C" }}>
                <div>{quote}</div>
              </div>
            </div>
          </div> : 
          <><div style={{ width: "100%" }}>
          <section className={imageClasses[index]} style={{ height }} />
        </div>
        <div style={{ width: "100vw", display: "flex" }}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                margin: 0,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                marginBottom: 30
              }}
            >
              <div style={{ width: "100%", height: matches ? "unset" : 830, textAlign: matches ? "unset" : "left", color: "#1B297C" }}>
                <div style={{margin : matches ? "unset" : 30, fontSize : matches ? "unset" : "4vw"}}>{quote}</div>
              </div>
            </div>
          </div></> }</>
      ))}
    </Carousel>
  );
}

export default HomeQuoteCarousel;
