import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/VisionValues/Banners/banner.webp";
import card1 from "../../images/VisionValues/Cards/1.webp";
import card2 from "../../images/VisionValues/Cards/2.webp";
import card3 from "../../images/VisionValues/Cards/3.webp";
import blogtile from "../../images/VisionValues/Tiles/blogtile.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/Card/Card";
import Poster from "../../components/Poster/Poster";
import Text from "../../components/Text/Text";
import { Grid,useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function VisionValues({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={ matches ? 500 : "unset"}>
        <>{matches ? <Heading color="#FFFFFF">Vision and Values</Heading> : <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>
          Vision and Values</p> }</> 
        </Banner>
      </div>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: matches ? 40 : 0,
                padding: matches ? "80px 40px" : "unset",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Poster image={blogtile}>
                    <Text
                      color="#FFFFFF"
                      fontSize={matches ? 32 : "5vw"}
                      style={{ margin:matches ? 0 : 30, padding: matches ? "10%" : "2%" }}
                    >
                      We endeavour to{" "}
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        enrich lives
                      </span>{" "}
                      by providing accessible and innovative financial products
                      and services to institutions, businesses and individuals
                      alike.
                    </Text>
                  </Poster>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: 1,
                    justifyContent: "center",
                    width : matches ? "unset" : "100%"
                  }}
                >
                  <div style={{ marginBottom : matches ? 0 : 0 }}>
                  <Card image={card1}>
                  <Text style={{ padding: 0, margin: 0, fontSize: matches ? 15 : "3vw", color: "#444444",fontWeight: 500 }}>
                      We combine our passion to serve an inclusive digital
                      economy with applied
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        {" "}
                        ingenuity
                      </span>{" "}
                      to formulate robust and creative financial solutions
                      through our digital platform.
                    </Text>
                  </Card>
                  </div>
                  <div style={{ marginBottom : matches ? 0 : 0 }}>
                  <Card image={card2}>
                    <Text style={{ padding: 0, margin: 0, fontSize: matches ? 15 : "3vw", color: "#444444",fontWeight: 500 }}>
                      Perpetual
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        {" "}
                        innovation
                      </span>{" "}
                      lies at the crux of our vision. We endeavour to engineer
                      and deliver cutting-edge technology to provide a dynamic
                      and seamless user experience.
                    </Text>
                  </Card>
                  </div>
                  <div style={{ marginBottom : matches ? 0 : 48 }}>
                    <Card image={card3}>
                      <Text style={{ padding: 0, margin: 0, fontSize: matches ? 15 : "3vw", color: "#444444",fontWeight: 500 }}>
                        Our work is driven by our earnest commitment to act with
                        <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                          {" "}
                          integrity
                        </span>{" "}
                        in the way we create value for our customers. Our
                        corporate ethics and principles stand resolute amidst the
                        mutable landscape of the digital era.
                      </Text>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
