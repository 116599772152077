import React from "react";
import ScrollToTopIcon from "./ScrollToTopIcon";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useLocation } from "react-router";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles({
  goTopHidden: {
    display: "none",
  },
  goTop: {
    display: "block",
  },
  icon: {
    cursor: "pointer",
    position: "fixed",
    bottom: 50,
    right: 50,
    zIndex: 9999,
    borderRadius: "100%",
    boxSizing: "border-box",
    transition: "box-shadow 0.2s ease-out",
    "&:hover": {
      boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.8)",
    },
  },
  iconMobile: {
    cursor: "pointer",
    position: "fixed",
    bottom: 50,
    right: 15,
    zIndex: 9999,
    borderRadius: "100%",
    boxSizing: "border-box",
    transition: "box-shadow 0.2s ease-out",
    "&:hover": {
      boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.8)",
    },
  },
});

const ScrollToTopButton = ({ showGoTop, scrollUp }) => {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div
        className={showGoTop ? classes.goTop : classes.goTopHidden}
        onClick={scrollUp}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <button
          className={clsx(classes.goTop, { [classes.goTopHover]: hover })}
        >
          <ScrollToTopIcon className={matches ? classes.icon : classes.iconMobile} />
        </button>
      </div>
    </>
  );
};
export default ScrollToTopButton;
