import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import React from "react";

export const MenuItems = [
  {
    icon: <DescriptionOutlinedIcon />,
    title: "Menu",
    items: [
      {
        title: "Chapter I",

        items: [
          {
            title: "subitem 1",
            to: "/index"
          },
          {
            title: "subitem 2",
            to: "/chart"
          },
          {
            title: "subitem 3",
            to: "/trendlines"
          }
        ]
      },
      {
        title: "Chapter II",
        description: "",
        items: [],
        to: "/thedowtheory"
      },
      {
        title: "Chapter III"
      }
    ]
  }
];
