import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Pressroom</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Pressroom</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
             The latest announcements, industry insights, and perspectives from
            SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}>  The latest announcements, industry insights, and perspectives from
            SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48

            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            > Indonesia’s GoTo Group raises over $1.3B in first pre-IPO close
          </Heading> : <p style={{color:"#1B297C",margin: 0 , marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>Indonesia’s GoTo Group raises over $1.3B in first pre-IPO close
          </p>}</>
            

            <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
            11 November 2021
            </Heading> : <p style={{fontSize:"5vw" ,color: "#444444",fontWeight: 500,margin: 0 , marginBottom: 30 }} > 11 November 2021</p>}</>
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
                Indonesia-based startup GoTo Group announced today it has secured more than $1.3 billion in the first close of its pre-IPO funding. 
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            The latest round was led by Abu Dhabi Investment Authority (ADIA), with participation from Avanda Investment Management, Fidelity International, Google, Permodalan Nasional Berhad (PNB), Primavera Capital, SeaTown Master Fund, Temasek, Tencent and Ward Ferry. Further investors are expected to join in its final close of the funding in the coming weeks.
            GoTo Group will use the proceeds to fuel its growth and bolster its customer service. 
            The company declined to comment on its valuation.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            According to media reports, GoTo Group’s valuation is estimated at between $28.5 billion and $30 billion. 
            Ride-hailing giant Gojek and e-commerce company Tokopedia, Indonesia’s two biggest startups, merged to form GoTo Group in May this year. GoTo Group is creating synergies with Gojek, Tokopedia and Goto Financial brands across transport, e-commerce, food and grocery delivery, logistics and financial service industries.

            GoTo Group, the largest technology group in the Southeast Asia nation, is preparing for a dual listing in New York and Jakarta later this year.   
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            GoTo Group claims that it generated more than 1.8 billion transactions in 2020, with gross transaction value of over $22 billion, contributing to more than 2% of Indonesia’s GDP. Indonesia has more than $1 trillion in GDP with a population of 270 million.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            “Indonesia and Southeast Asia are some of the most exciting growth markets in the world, and the backing we’ve secured shows the confidence that investors have in the region’s rapidly expanding digital economy and our market-leading position,” said Andre Soelistyo, CEO of GoTo Group. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,  fontSize: matches ? "unset" : "4vw",margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            “Consumer demand is being unlocked by growth in digital adoption that has brought many new users online. As a result, demand for our service continues to increase, underscored by our commitment to continuously deliver selection, value and convenience to users across the ecosystem.” 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw",margin: matches ? "unset" : 0}}>
            “We foresee secular growth opportunities for Indonesia and GoTo across e-commerce, on-demand mobility and fintech – all segments in which Primavera has extensive investment experience. We’re excited to partner and grow together with GoTo, leveraging our expertise and resources where helpful for management,” Michael Woo, managing director of Primavera Capital Group. 
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
