import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function SvgComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <svg width = {matches ? 887 : 590} height = {matches ? 243:245}
      data-name="Component 33 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      // width={747}
      // height={243}
      {...props}
    >
      <text
        data-name="We pride ourselves in driving financial efficiency across Asia"
        fill="#fff"
        fontSize= {matches ? 46 : "6vw"} 
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontWeight={600}
      >
        <tspan x={0} y={matches ? 64 : 80}>
          {"We pride ourselves in "}
        </tspan>
        <tspan x={0} y={matches ? 145 : 121}>
          {"driving financial "}
        </tspan>
        <tspan x={0} y={matches ? 226 : 165}>
          {"efficiency across Asia"}
        </tspan>
      </text>
    </svg>
  );
}

export default SvgComponent;
