import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/ClientNetwork/Banners/banner.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import ClientNetworkCarousel from "../../components/Carousels/ClientNetworkCarousel";
import Text from "../../components/Text/Text";
import LogoCascade from "../../components/LogoCascade/LogoCascade";
import {
  governmentLogos,
  bankingGovLogos,
  bankingPrivateLogos,
  bankingInternationalLogos,
  financialInstitutionLogos,
  eCommerceLogos,
  transportationLogos,
  telecomEntertainmentLogos,
  insuranceLogos,
  realEstateLogos,
  educationLogos,
} from "../../components/Svg/ClientLogos/ClientLogos";
import { Grid,useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../components/appbar/actions";

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "black",
    height: 750,
    position: "absolute",
    left: "10vw",
    minWidth: "40vw",
  },
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  circle: {
    backgroundColor: "#FFFFFF",
    height: 400,
    width: 400,
    borderRadius: 400,
  },
  photoPlaceholder: {
    width: 400,
    height: 250,
    backgroundColor: "grey",
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tiles: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "row",
  },
  statistic: {
    flexGrow: 1,
  },
});

const transitionTime = 1500;
const interval = 6000;

export default function ClientNetwork({ scrollToRef }) {
  // const [quote, setQuote] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ? 500 : "unset"}>
        <>{matches ?   <Heading color="#FFFFFF">Our Valued Customers</Heading>:   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold", textAlign : "center"}}>Our Valued Customers</p> }</>
         
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
            <div className={matches ? classes.textSection : classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500 ,fontSize: matches ? "unset" : "4vw" , marginTop: 0, marginBottom: matches ? "unset" : 20}}>
                  While consumer patterns have evolved over the past 20 years,
                  we remain steadfast in delivering creative solutions and in
                  developing efficacious means to grapple with the shifting
                  trends and emerging technology in the digital payment
                  landscape.
                </Text>
                <Text style={{color: "#444444",fontWeight: 500 ,fontSize: matches ? "unset" : "4vw", marginTop: 0, marginBottom: matches ? "unset" : 0}}>
                  We strive to strike a holistic equilibrium that affords our
                  clients greater latitude to draw strategic insights and to
                  meet the dynamic needs unique to each industry portfolio.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.tiles} style={{ height: matches ? 700 : 500 }}>
        <div style={{ width: "100vw" }}>
          <ClientNetworkCarousel
            height={matches ? 700 : 600}
            transitionTime={transitionTime}
            interval={interval}
          />
        </div>
      </section>
      <section >
        <Grid>
          <LogoCascade style={{marginTop : matches ? "unset" : 100}}
            header="GOVERNMENT INSTITUTIONS"
            noBorder
            withMarginTop
            logos={governmentLogos}
          />
          <LogoCascade header="BANKING - GOVERNMENT" logos={bankingGovLogos} />
          <LogoCascade header="BANKING - PRIVATE" logos={bankingPrivateLogos} />
          <LogoCascade
            header="BANKING - INTERNATIONAL"
            logos={bankingInternationalLogos}
          />
          <LogoCascade
            header="FINANCIAL INSTITUTIONS"
            logos={financialInstitutionLogos}
          />
          <LogoCascade 
            header="E-COMMERCE, E-WALLET & E-MONEY"
            logos={eCommerceLogos}
            withMarginLeft
          />
          <LogoCascade header="TRANSPORT" logos={transportationLogos} />
          <LogoCascade
            header="TELECOMMUNICATION & ENTERTAINMENT"
            logos={telecomEntertainmentLogos}
          />
          <LogoCascade header="INSURANCE" logos={insuranceLogos} />
          <LogoCascade header="REAL ESTATE" logos={realEstateLogos} />
          <LogoCascade header="EDUCATION" logos={educationLogos} />
        </Grid>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
