import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Container  from "@material-ui/core/Container";
import Banner from "../../components/Banner/Banner";
import efficiencybanner from "../../images/Home/Banners/efficiencybanner.webp";
import EfficiencySlogan from "../../components/Svg/EfficiencySlogan_id";
// import Content from "../../components/Content/Content";
import HomeBannerCarousel from "../../components/Carousels/HomeBannerCarousel_id";
import Button from "../../components/Button/Button";
// import Heading from "../../components/Text/Heading";
import Tile from "../../components/Tile/Tile";
import TileBanks from "../../components/Tile/TileBanks";
import agents from "../../images/Home/Tiles/agents.webp";
import banks from "../../images/Home/Tiles/banks.webp";
import partners from "../../images/Home/Tiles/partners.webp";
import BankStat from "../../components/Svg/BanksStat_id";
import PartnersStat from "../../components/Svg/PartnersStat_id";
import AgentsStat from "../../components/Svg/AgentsStat_id";
import Footer from "../../components/Footer/Footer";
import HomeQuoteCarousel from "../../components/Carousels/HomeQuoteCarousel_id";
import Text from "../../components/Text/Text";
// import TextDataWeb from "../../components/Text/TextDataWeb";
// import TextDataMobile from "../../components/Text/TextDataMobile";

import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import { Link, useLocation, matchPath } from "react-router-dom";
// import { important } from "polished";
import Actions from "../../components/appbar/actions";

const useStyles = makeStyles({
  carouselWrapper: {
    height: "100vh",
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "black",
    height: "100vh",
    position: "absolute",
    left: "10vw",
    minWidth: "40vw",
  },
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  circle: {
    backgroundColor: "#FFFFFF",
    height: 400,
    width: 400,
    borderRadius: 400,
  },
  photoPlaceholder: {
    width: 400,
    height: 250,
    backgroundColor: "grey",
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tiles: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "row",
  },
  tiles2: {
    width: "100%",
    height: 300,
    // display: "flex",
    // justifyContent: "row",
    marginBottom: 600
  },
  statistic: {
    flexGrow: 1,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    marginTop: 25,
    marginLeft: 4,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
    display : "none"
  },
  textMobile: {
    fontFamily: "Montserrat",
    fontSize: "4vw",
    fontWeight: 500,
    margin: 30,
    color: "rgb(68, 68, 68)",
    marginBottom: 0

  },
});

const heading = makeStyles({
  h1: {
    fontSize: "65px",
    padding: 0,
    margin: 0,
    color: "#293EBB",
  },
  h4: {
    fontSize: "8vw",
    padding: 0,
    margin: 0,
    color: "#1B297C",
  },
});

export default function Home({ scrollToRef }) {
  const classes = useStyles();
  const classes2 = heading();
  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  return (
    <>
      <div ref={scrollToRef} />
      {/* <Container maxWidth="md"> */}
      <Grid>
        <HomeBannerCarousel height={matches ? "95vh" : "20vh"} />
        <p className={matches ? classes.text : classes.textMobile}>
          Kami adalah penyedia solusi pembayaran digital pertama di Indonesia.
          Setiap tahun, kami memproses lebih dari 162 juta transaksi, dengan
          nilai total 10.3 milyar dollar.
        </p>
        <section id="mainSection" className={classes.mainSection}>
          <div className={classes.row}>
            <div
              style={{
                margin: matches ? 100 : 30,
                // marginTop : 100,
                // marginBottom : 100,
                width: matches ? "70%" :"100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                // marginLeft: 185 ,
                // marginRight: 34
                marginBottom : matches ? "unset" : 48

              }}
            >
              <div className={classes.textSectionWrapper}>
                <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
                <div className={matches ? classes.textSection : classes.textSectionMobile}>
                <p className={matches ? classes2.h1 :classes2.h4} style={{fontFamily: "Roboto",fontWeight: "bold"}}>Tentang SYB</p>
                  {/* <Text style={{color: "#444444", fontWeight: 500, lineHeight:matches ? 1 : 1.5 }} fontSize={matches ? 20 :18}  >
                    PT Sarana Yukti Bandhana (
                    <span style={{ color: "#F0A202", fontWeight: "bold" }}>
                      SYB
                    </span>
                    ) adalah penyedia dan operator sistem pembayaran. Untuk
                    lebih dari 20 tahun, SYB telah merintis ekosistem pembayaran
                    digital yang menjamin transaksi di proses secara efisien dan
                    aman.
                  </Text>
                  <Text fontSize={matches ? 20 :18} style={{color: "#444444",fontWeight: 500}}>
                    SYB menawarkan beragam saluran pembayaran digital, melayani
                    berbagai sektor, mulai dari utilitas public, perbankan,
                    keuangan, asuransi, pendidikan, telekomunikasi, hiburan,
                    game online, e-commerce, sampai e-wallet dan e-money.
                  </Text>
                  <Text style={{color: "#444444",fontWeight: 500}} fontSize={matches ? 20 :18}>
                    Kami berusaha keras untuk menjadi pelopor di ranah digital
                    dan memberikan pelanggan kami solusi keuangan yang aman dan
                    inovatif, dirancang untuk memajukan jasa keuangan di seluruh
                    Asia.
                  </Text> */}

                  {(matches ? 
                    <><Text style={{ color: "#444444", fontWeight: 500, lineHeight: 1}} fontSize={20}>
                      PT Sarana Yukti Bandhana (
                      <span style={{ color: "#F0A202", fontWeight: "bold" }}>
                        SYB
                      </span>
                      ) adalah penyedia dan operator sistem pembayaran. Untuk
                      lebih dari 20 tahun, SYB telah merintis ekosistem pembayaran
                      digital yang menjamin transaksi di proses secara efisien dan
                      aman.
                    </Text><Text fontSize={20} style={{ color: "#444444", fontWeight: 500 }}>
                        SYB menawarkan beragam saluran pembayaran digital, melayani
                        berbagai sektor, mulai dari utilitas public, perbankan,
                        keuangan, asuransi, pendidikan, telekomunikasi, hiburan,
                        game online, e-commerce, sampai e-wallet dan e-money.
                      </Text><Text style={{ color: "#444444", fontWeight: 500 }} fontSize={20}>
                        Kami berusaha keras untuk menjadi pelopor di ranah digital
                        dan memberikan pelanggan kami solusi keuangan yang aman dan
                        inovatif, dirancang untuk memajukan jasa keuangan di seluruh
                        Asia.
                      </Text></> : 
                      <><Text style={{ color: "#444444", fontWeight: 500, lineHeight: 1.5 }} fontSize={"4vw"}>
                      PT Sarana Yukti Bandhana (
                      <span style={{ color: "#F0A202", fontWeight: "bold" }}>
                        SYB
                      </span>
                      ) adalah penyedia dan operator sistem pembayaran. Untuk
                      lebih dari 20 tahun, SYB telah merintis ekosistem pembayaran
                      digital yang menjamin transaksi di proses secara efisien dan
                      aman.
                        SYB menawarkan beragam saluran pembayaran digital, melayani
                        berbagai sektor, mulai dari utilitas public, perbankan,
                        keuangan, asuransi, pendidikan, telekomunikasi, hiburan,
                        game online, e-commerce, sampai e-wallet dan e-money.
                        Kami berusaha keras untuk menjadi pelopor di ranah digital
                        dan memberikan pelanggan kami solusi keuangan yang aman dan
                        inovatif, dirancang untuk memajukan jasa keuangan di seluruh
                        Asia.
                      </Text></>
                  )}
                      
                </div>
              </div>
              <Link
                to={`/${language}/ourjourney`}
                style={{ textDecoration: "none" }}
              >
                <Button style={{ marginRight: 40 }}>Telusuri</Button>
              </Link>
            </div>
          </div>
        </section>
        <Link to={`/${language}/clients`}>
          <section className={ matches ? classes.tiles : classes.tiles2}>
            <Tile background={partners}>
              <PartnersStat />
            </Tile>
            <TileBanks background={banks}>
              <BankStat />
            </TileBanks>
            <Tile background={agents}>
              <AgentsStat />
            </Tile>
          </section>
        </Link>
        <section className={classes.banner}>
          <Banner
            background={efficiencybanner}
            height={matches ? 500 : "unset"}
            style={{
              alignItems: "flex-start",
              backgroundPosition: "bottom",
            }}
          >
             <div style={{ paddingLeft: matches ? 40 : 10 }}>
              <EfficiencySlogan />
            </div>
          </Banner>
        </section>
        <section className={classes.tiles} style={{ height: matches ? 700 : 1200 }}>
          <div style={{ width: "100vw" }}>
            <HomeQuoteCarousel
              transitionTime={1500}
              interval={10000}
              height={ matches ? 700 : 330}
            />
          </div>
        </section>
        <>{matches ? <Footer language="en" /> : <Actions />}</>
      </Grid>
      {/* </Container> */}
    </>
  );
}
