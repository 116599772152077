import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Pressroom</Heading> :   
            <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Pressroom</p> }</> 

          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
             The latest announcements, industry insights, and perspectives from
            SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}>  The latest announcements, industry insights, and perspectives from
            SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48
            }}
          >
          <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            > BI-Fast officially launch, Interbank Transfer Fee becomes Rp 2.500
          </Heading> : <p style={{color:"#1B297C",margin: 0 , marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>BI-Fast officially launch, Interbank Transfer Fee becomes Rp 2.500</p>}</>
            
            <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
            21 December 2021
            </Heading> : <p style={{fontSize:"5vw", color: "#444444",fontWeight: 500, margin: 0,marginBottom: 30 }} >21 December 2021</p>}</>
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw" , margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
                Bank Indonesia (BI) officially launched Bank Indonesia Fast Payment (BI-Fast, 
                a payment system (SP) infrastructure that can facilitate retail payments using various instruments and channels in real time for 24 hours. 
                BI-Fast pricing scheme from BI to Participants is set at Rp 19 per transaction, 
                while interbank transfer fee from Participants to Customers is set at a maximum of Rp 2.500 per transaction.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw" , margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            BI Governor Perry Warjiyo explains that BI-Fast as one of the implementations of Blueprint System Pembayaran Indonesia (BSPI) 2025 vision is a form of digital transformation to push inclusive and equitable economic growth as well as support national economic recovery program. Implementation of BI-Fast by banks to their customers will be done in phases.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw" , margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            “I hope that the launch of BI-Fast will accelerate the digitalization of national financial economy, integrate the payment system industry ecosystem end-to-end from digital banking, fintech, e-commerce and consumers, encourage financial economic inclusion and encourage national economic recovery.” said Perry Warjiyo at the launch of BI-Fast, Tuesday (21/12/2021).
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw" , margin: matches ? "unset" : 0,marginBottom: matches ? "unset" : 30}}>
            In the early phase in December 2021, BI-Fast implementation will focus on individual credit transfer services with 21 batch 1 participants who are ready to provide BI-Fast services. For other prospective participants who have not yet entered as participants in batch 1, Bank Indonesia will continue to open the next wave of batch to become BI-Fast participants. Furthermore, BI-Fast services will gradually expand to include bulk credit, direct debit and request for payment services.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500 , fontSize: matches ? "unset" : "4vw" , margin: matches ? "unset" : 0}}>
            The 21 participants of the first batch of BI-Fast are Bank Tabungan Negara, Bank Syariah Indonesia, Bank DBS Indonesia, Bank Rakyat Indonesia, Bank Permata, Bank OCBC NISP, Bank Mandiri, Bank Tabungan Negara Unit usaha Syariah (UUS), Bank Danamon Indonesia, Bank Permata UUS, Bank CIMB Niaga, Bank CIMB Niaga UUS, Bank Central Asia, Bank Danamon Indonesia UUS, Bank BCA Syariah, Bank UOB Indonesia, Bank Sinarmas, Bank Mega, Bank Citibank NA, Bank Negara Indonesia and Bank Woori Saudara Indonesia.
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
