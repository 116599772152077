import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    fontFamily: "Roboto",
    position: "relative",
    cursor: "pointer",
    padding: "6px 10px",
    borderRadius: "8px",
    fontSize: 18,
    fontWeight: "bold",
    border: "3px solid #F0A202",
    color: "#F0A202",
    boxShadow: "0px 2px 3px 0px rgba(201, 136, 2, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    transition: "box-shadow 0.2s ease-out",
    "&:hover": {
      boxShadow: "0px 2px 10px 0px rgba(201, 136, 2, 0.8)",
    },
  },
});

function Button({ width, height, type = "button", children, style }) {
  const classes = useStyles();

  return (
    <button
      className={classes.button}
      style={{ width, height, ...style }}
      type={type}
    >
      {children}
    </button>
  );
}

export default Button;
