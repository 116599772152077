import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import background1 from "../../images/ClientNetwork/Carousel/1.webp";
import background2 from "../../images/ClientNetwork/Carousel/2.webp";
import background3 from "../../images/ClientNetwork/Carousel/3.webp";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "../Text/Heading";
import Text from "../Text/Text";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "rgba(0,0,0,0.6)",
    height: 750,
    position: "absolute",
    left: "10vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "50px",
  },
  carouselSlide1: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide2: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide3: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainSection: {
    backgroundColor: "#00004D",
    margin: 0,
    color: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textLarge: {
    fontFamily: "Roboto",
    fontSize: 80,
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    margin: 0,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
  },
});

const quotes = [
  {
    heading: "500+ Corporate Partners",
    content:
      "We endeavour to create personalized experiences at every stage of the customer life cycle. With our understanding of the customer payment patterns unique to each industry portfolio, we offer nuanced behavioural insights and custom solutions to redefine the contemporary purchase and collection process.",
  },
  {
    heading: "10K+ Agencies",
    content:
      "We advocate for equal opportunity and are committed to fostering inclusivity in today’s digital economy. We strive to facilitate unbanked customers in their journey to access modern payment and collection methods as well as in training sole proprietors and retailers to be active and informed participants in the digital payment ecosystem.",
  },
  {
    heading: "40+ Banks",
    content:
      "The digital payment ecosystem encompasses a variegated array of banks, be it government or commercial, international or local, or large to niche banks. We cater to the entire spectrum of banks and proffer digital solutions to seamlessly process bill payments and top-ups or reloads.",
  },
];

function HomeQuoteCarousel({ height, transitionTime = 1500, interval = 6000 }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const indicatorStyles = {
    display: "inline-block",
    marginRight: "10px",
    color: "#fff",
    cursor: "pointer",
    height: 10,
    width: 10,
    zIndex: 10,
    // marginRight: 8,
    borderRadius: "50%",
    "-webkit-transition": "background-color 0.6s ease",
    transition: "background-color 0.6s ease",
    border: "2px solid #141413",
    transform: matches ? "translateX(200px) translateY(-50px)" : "translateX(140px) translateY(12px)",
  };

  const imageClasses = [
    classes.carouselSlide1,
    classes.carouselSlide2,
    classes.carouselSlide3,
  ];

  return (
    <div className={classes.carouselWrapper} style={{ height, width: "100%" }}>
      <Carousel
        autoPlay
        swipeable={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        showIndicators={true}
        transitionTime={transitionTime}
        interval={interval}
        dynamicHeight={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            style={{
              ...indicatorStyles,
              background: isSelected ? "#141413" : "transparent",
              outline: isSelected ? "1px solid #141413" : "none",
              
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        {quotes.map((quote, index) => (
        <div style={{ width: "100vw", display: matches ? "flex" : "unset", height : matches? 700:250 }}>
        <div style={{ width:  matches ? "50%" : "100%" }}>
          <section className={imageClasses[index]} style={{ height : matches? 700:250}} />
        </div>
            <div
              style={{
                width:  matches ? "50%" : "100%",
                backgroundColor: "#FFFFFF",
                margin: 0,
                display: "flex",
                flexWrap: matches ? "wrap" : "unset",
                flexDirection: matches ? "column" : "unset",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <div style={{ width: 500, color: "#1B297C" }}>
                <div style={{margin: matches ? "unset" : 30}}>
                  {quote.heading && (
                      <>{matches ? <Heading variant="h2" color="#00004D">
                      {quote.heading}
                    </Heading> : <p style={{color:"#00004D", fontSize: "8vw",padding: 0, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}} >{quote.heading}</p> }</>
                     
                  )}
                  <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw"}}>{quote.content}</Text>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default HomeQuoteCarousel;
