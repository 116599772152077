import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import background1 from "../../images/Home/Carousel/HomeBanner/1.webp";
import background2 from "../../images/Home/Carousel/HomeBanner/2.webp";
import background3 from "../../images/Home/Carousel/HomeBanner/3.webp";
import background4 from "../../images/Home/Carousel/HomeBanner/4.webp";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles({
  carouselWrapper: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "rgba(0,0,0,0.6)",
    position: "absolute",
    left: "10vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "50px",
    padding: 50,
  },
  carouselBannerMobile: {
    backgroundColor: "rgba(0,0,0,0.6)",
    position: "absolute",
    left: "30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "18px",
    padding: 30,
  },
  carouselSlide1: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide2: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide3: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide4: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background4})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainSection: {
    backgroundColor: "#00004D",
    margin: 0,
    color: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textLarge: {
    fontFamily: "Roboto",
    fontSize: "90px",
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
    lineHeight: 1.0,
    "@media (max-width: 991px)": {
      fontSize: "65px",
    },
  },
  textLargeMobile: {
    fontFamily: "Roboto",
    fontSize: "10vw",
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
    lineHeight: 1.0,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    marginTop: 25,
    marginLeft: 4,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
  },
  textMobile: {
    fontFamily: "Roboto",
    fontSize: 22,
    padding: 0,
    marginTop: 25,
    marginLeft: 4,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "384px",
    display : "none"
  },
});

function CarouselBanner({ height }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className={classes.carouselWrapper} style={{ height, width: "100%" }}>
      <div className={matches ? classes.carouselBanner : classes.carouselBannerMobile } style={{ height: matches ? height : "15vh" }}>
        <p className={matches ? classes.textLarge : classes.textLargeMobile}>INGENUITY</p>
        <p className={matches ? classes.textLarge : classes.textLargeMobile}>INNOVATION</p>
        <p className={matches ? classes.textLarge : classes.textLargeMobile}>INTEGRITY</p>
        <p className={matches ? classes.text : classes.textMobile}>
          We are Indonesia’s first digital payment solutions provider. On a
          yearly basis, we process more than 162 million transactions, amounting
          to 10.3 billion USD.
        </p>
      </div>
      <Carousel
        autoPlay
        swipeable={false}
        animationHandler="fade"
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        showIndicators={true}
        transitionTime={1500}
        interval={4000}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            style={{
              display: "none",
            }}
          />
        )}
      >
        <section className={classes.carouselSlide1} style={{ height }} />
        <section className={classes.carouselSlide2} style={{ height }} />
        <section className={classes.carouselSlide3} style={{ height }} />
        <section className={classes.carouselSlide4} style={{ height }} />
      </Carousel>
    </div>
  );
}

export default CarouselBanner;
