import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  styled,
} from "@mui/material";
import { useUIContext } from "../context/ui";
import CloseIcon from "@mui/icons-material/Close";
import { DrawerCloseButton } from "../styles/appbar";
import { lighten } from "polished";
import { Colors } from "../styles/theme";
import NavBarLink from "../NavBarLink/NavBarLink";
import NavBarDropdown from "../NavBarDropdown/NavBarDropdown";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import LanguageToggleId from "../LanguageToggle/LanguageToggle_id";
import * as React from 'react';
import { matchPath } from "react-router";
import { useLocation } from "react-router";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Link } from "react-router-dom";
import {DrawerWidth} from "../styles/theme";
import MailIcon from '@mui/icons-material/Mail';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;


export default function AppDrawer() {
  const { drawerOpen } = useUIContext();
  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";

    const [open, setState] = React.useState(false);

    const handleClick = () => {
      setState(!open);
    };

    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...open, [anchor]: open });
    };

  // const Toggle = language == "en" ? LanguageToggle : LanguageToggleId;
  return (
    <>
      {drawerOpen 
      // && (
      //   <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
      //     <CloseIcon
      //       sx={{
      //         fontSize: "2.5rem",
      //         color: lighten(0.09, Colors.white),
      //       }}
      //     />
      //   </DrawerCloseButton>
      // )
      }
      <Drawer open={drawerOpen}>
        <List style={{width: DrawerWidth }}>
          <ListItem onClick={handleClick}>
            <ListItemText primary={language == "en" ? "About Us" : "Tentang Kami"}  />
            {open ? <ExpandLess /> : <ExpandMore/>}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to={`/${language}/visionvalues`} >
                <ListItemButton  sx={{pl:4}}>
                  <ListItemText primary={language == "en" ? "Vision and Values" : "Visi dan Nilai-Nilai"} style={{ color: '#000000' }}/>
                </ListItemButton>
              </ListItem>
              <ListItem component={Link} to={`/${language}/ourjourney`} >
                <ListItemButton sx={{pl:4}}>
                  <ListItemText primary={language == "en" ? "Our Journey" : "Perjalanan Kami"} style={{ color: '#000000' }}/>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <MiddleDivider />
          <ListItem component={Link} to={`/${language}/capabilities`} >
            <ListItemButton>
              <ListItemText primary= {language == "en" ? "Capabilities" : "Kemampuan"} style={{ color: '#000000' }} />
            </ListItemButton>
          </ListItem>
          <MiddleDivider />
          <ListItem component={Link} to={`/${language}/clients`} >
            <ListItemButton>
              <ListItemText primary= {language == "en" ? "Our Clients" : "Klien Kami"} style={{ color: '#000000' }} />
            </ListItemButton>
          </ListItem>
          <MiddleDivider />
          <ListItem component={Link} to={`/${language}/news`} >
            <ListItemButton>
              <ListItemText primary=  {language == "en" ? "News" : "Berita"} style={{ color: '#000000' }}/>
            </ListItemButton>
          </ListItem>
          <MiddleDivider />
          <ListItem component={Link} to={`/${language}/contact`} >
            <ListItemButton>
              <ListItemText primary= {language == "en" ? "Contact" : "Kontak"} style={{ color: '#000000' }}/>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
