import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";


const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Ruang Pres</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Ruang Pres</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
            Pengumuman terbaru, wawasan industri dan perspektif dari SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}> Pengumuman terbaru, wawasan industri dan perspektif dari SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48

            }}
          >
            <>{matches ? <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            > BI-Fast Diresmikan, Biaya Transfer Antarbank Jadi Rp 2.500
            </Heading> : <p style={{color:"#1B297C", margin: 0,marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}> BI-Fast Diresmikan, Biaya Transfer Antarbank Jadi Rp 2.500</p>}</>
            <>{matches ? <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
              21 Desember 2021
            </Heading> : <p style={{fontSize:"5vw",color: "#444444",fontWeight: 500, margin: 0,marginBottom: 30 }} >21 Desember 2021</p>}</>
            <div className={classes.textSectionWrapper}>
              <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
                Bank Indonesia (BI) resmi meluncurkan Bank Indonesia Fast Payment (BI-Fast), 
                yaitu infrastruktur sistem pembayaran (SP) yang dapat memfasilitasi pembayaran 
                ritel menggunakan berbagai instrumen dan kanal secara real time 24 jam penuh. 
                Penetapan skema harga BI-Fast dari BI ke peserta ditetapkan Rp 19 per transaksi, 
                sedangkan biaya transfer antar bank dari peserta ke nasabah ditetapkan maksimal Rp 2.500 per transaksi.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Gubernur BI Perry Warjiyo menyampaikan, 
            BI-Fast sebagai salah satu implementasi visi Blueprint Sistem Pembayaran Indonesia (BSPI) 2025 merupakan bentuk transformasi digital untuk mendorong pertumbuhan ekonomi yang inklusif dan merata, 
            serta mendukung program pemulihan ekonomi nasional. 
            Implementasi BI-Fast oleh bank kepada nasabahnya akan dilakukan secara bertahap.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            “Saya berharap peluncuran BI-Fast akan mempercepat digitalisasi ekonomi keuangan nasional, 
            mengintegrasikan ekosistem industri sistem pembayaran secara end-to-end dari perbankan digital, 
            fintech, e-commerce, dan konsumen, mendorong inklusi ekonomi keuangan, 
            serta mendorong pemulihan ekonomi nasional,” kata Perry Warjiyo di peluncuran BI-Fast secara daring, Selasa (21/12/2021).
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Pada tahap awal di Desember 2021, implementasi BI-Fast fokus pada layanan transfer kredit individual dengan 21 peserta batch 1 yang telah siap menyediakan layanan BI-Fast. 
            Bagi calon peserta lainnya yang belum masuk sebagai peserta batch pertama, Bank Indonesia tetap membuka gelombang berikutnya untuk menjadi peserta BI-Fast. 
            Selanjutnya, layanan BI-Fast diperluas secara bertahap mencakup layanan bulk credit, direct debit, dan request for payment.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0}}>
            Sebanyak 21 peserta BI-Fast batch pertama yaitu Bank Tabungan Negara, Bank Syariah Indonesia, Bank DBS Indonesia, Bank Rakyat Indonesia, Bank Permata, Bank OCBC NISP, Bank Mandiri, Bank Tabungan Negara Unit usaha Syariah (UUS), Bank Danamon Indonesia, Bank Permata UUS, Bank CIMB Niaga, Bank CIMB Niaga UUS, Bank Central Asia, Bank Danamon Indonesia UUS, Bank BCA Syariah, Bank UOB Indonesia, Bank Sinarmas, Bank Mega, Bank Citibank NA, Bank Negara Indonesia, dan Bank Woori Saudara Indonesia.
            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
