import styled from "styled-components";

export const VideoBackground = styled.video`
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #ffffff;
  position: absolute;
  z-index: 9999999999999;
`;
