import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/Capabilities/Banners/banner.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import Cascade from "../../components/Cascade/Cascade";
import Logo1 from "../../images/Logos/Capabilities/1Sun.svg";
import Logo2 from "../../images/Logos/Capabilities/2Fujitsu.svg";
import Logo3 from "../../images/Logos/Capabilities/3IBM.svg";
import Logo4 from "../../images/Logos/Capabilities/4HP.svg";
import Logo5 from "../../images/Logos/Capabilities/5VMWare.svg";
import Logo6 from "../../images/Logos/Capabilities/6F5.svg";
import Logo7 from "../../images/Logos/Capabilities/7Cisco.svg";
import Logo8 from "../../images/Logos/Capabilities/8Watchguard.svg";
import Logo9 from "../../images/Logos/Capabilities/9Thales.svg";
import Logo10 from "../../images/Logos/Capabilities/10Symantec.svg";
import Certificate1 from "../../images/Capabilities/Certifications/4.jpeg";
import Certificate2 from "../../images/Capabilities/Certifications/3.jpeg";
import Certification from "../../components/Certification/Certification";
import Service from "../../components/Service/Service";
import Card1 from "../../images/Capabilities/Cards/1.webp";
import Card2 from "../../images/Capabilities/Cards/2.webp";
import Card3 from "../../images/Capabilities/Cards/3.webp";
import Card4 from "../../images/Capabilities/Cards/4.webp";
import { Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

function Logo({ src, height = 100, width = "MAX(10vw, 200px)" }) {
  return (
    <img src={src} style={{ height, width, objectFit: "contain" }} alt="logo" />
  );
}

const icons = [
  <Logo src={Logo1} />,
  <Logo src={Logo2} />,
  <Logo src={Logo3} />,
  <Logo src={Logo4} />,
  <Logo src={Logo5} />,
  <Logo src={Logo6} />,
  <Logo src={Logo7} />,
  <Logo src={Logo8} />,
  <Logo src={Logo9} />,
  <Logo src={Logo10} />,
];



export default function Capabilities({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const services = [
    <Service 
      title="Integrasi “Application Programming Interface” (API)"
      content="Sebelum integrasi dimulai, kami menyediakan spesifikasi teknis yang diperlukan untuk secara akurat menangkap kebutuhan biller dan collecting agent kami."
      image={Card1}
    />,
    <Service 
      title="Menetapkan Standar Operasional Prosedur (SOP)"
      content="Dengan lebih dari 20 tahun pengetahuan dan keahlian industri, kami telah menetapkan SOP komprehensif untuk memperkuat fondasi solusi digital kami dan untuk memandu klien kami melalui lanskap digital yang bermacam-ragam."
      image={Card2}
    />,
    <Service 
      title="Rekonsiliasi Transaksi"
      content="Kami memastikan bahwa semua transaksi yang dilakukan melalui platform digital kami sepenuhnya dapat dipertanggung jawabkan dan dapat dilacak melalui proses rekonsiliasi kami."
      image={Card3}
    />,
    <Service 
      title="Helpdesk IT & Operasional"
      content="Tim kami tersedia 24/7 untuk melayani klien kami dan mengelola pertanyaan teknis atau operasional apa pun terkait integrasi API, koneksi jaringan, transaksi, dll."
      image={Card4}
    />,
  ];

  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ? 500 : "unset"}>
          <>{matches ? <Heading color="#FFFFFF">Kemampuan</Heading> :  
           <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Kemampuan</p> }</>
        </Banner>
      </div>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: matches ? 40 : 0,
                padding: matches ? "80px 40px" : "",
                display: "flex",
                flexDirection: "column",
                margin: matches ? "" : 30
              }}
            >
              <>{matches ? <Heading variant="h3">Jasa</Heading> :  
                <p style={{color:"#293EBB", fontSize: "8vw",padding: 0, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Jasa</p> }</>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "-webkit-fill-available",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    }}
                  >
                    {services.map((i) => (
                      <div
                        style={{
                          width: "18vw",
                          maxWidth: 350,
                          minWidth: 335,
                          display: "flex",
                          justifyContent: "center",
                          margin: matches ? "40px 0px" : "",
                        }}
                      >
                        {i}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: matches ? 40 : 0,
                padding: matches ? "80px 40px" : "30px",
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #707070",
              }}
            >
                <>{matches ?   <Heading variant="h3">Sistem & Infrastruktur</Heading> :  
                <p style={{color:"#293EBB", fontSize: "8vw",padding: 0, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Sistem & Infrastruktur</p> }</>
            
              <Text fontSize={matches ? 18 : "4vw"} style={{color: "#444444",fontWeight: 500}}>
                Kami berinvestasi dalam teknologi terpercaya dan mutakhir untuk
                memastikan bahwa infrastruktur pembayaran digital kami didukung
                oleh perangkat lunak dan perangkat keras berkaliber tinggi. Kami
                menerapkan solusi dan sistem yang terbukti, terukur, dan aman
                guna meyakinkan klien kami bahwa setiap permintaan transaksi
                diakui dibawah satu milidetik. Kami bangga dalam mencapai
                ketersediaan sistem yang konsisten dan kinerja berkaliber tinggi
                untuk melindungi klien kami dari gangguan sistemik.
              </Text>
              <Cascade
                items={icons.map((i) => (
                  <div
                    style={{
                      minWidth: "20vw",
                      display: "flex",
                      justifyContent: "center",
                      margin: matches ?"40px 20px" : 30,
                    }}
                  >
                    {i}
                  </div>
                ))}
                margin={0}
                padding={0}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: matches ? 40 : 0,
                padding: matches ? "80px 40px 20px 40px" :  30,
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #707070",
              }}
            >
                <>{matches ? <Heading variant="h3">Sertifikasi</Heading> :  
                <p style={{color:"#293EBB", fontSize: "8vw",padding: 0, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Sertifikasi</p> }</>
            
              <Text fontSize={matches ? 18 : "4vw"} style={{color: "#444444",fontWeight: 500}}>
                Kami bertujuan untuk menjadi mitra terpercaya pilihan Anda dan
                kami menjunjung tinggi komitmen ini dengan menerapkan
                langkah-langkah dan sistem yang memenuhi standar internasional
                untuk keamanan informasi dan manajemen kualitas.
              </Text>
            </div>
          </div>
        </div>
        <div
          style={{
            display: matches ? "flex" : "block",
            width: "100%",
            minHeight: 500,
            flexWrap: "wrap",
          }}
        >
          <Certification
            src={Certificate1}
            title="ISO/IEC 27001:2013"
            body="ISO 27001:2013 adalah standar internasional untuk manajemen keamanan informasi. SYB telah memenuhi kriteria internasional untuk menetapkan, mengoperasikan, meninjau, dan terus menerus meningkatkan sistem manajemen keamanan informasi kami."
          />
          <Certification
            src={Certificate2}
            title="ISO/IEC 9001:2015"
            body="ISO 9001 adalah standar internasional untuk sistem manajemen mutu. Sertifikasi ini menunjukkan dedikasi SYB yang kuat untuk memastikan kualitas yang konsisten di seluruh penyediaan produk dan layanan kami."
            dark
          />
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
