import React from "react";
import Text from "../Text/Text";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Certification({ src, dark, title, body }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div
      style={{
        flex: 1,
        minWidth: matches ? 500 : 0,
        display:  matches ? "flex" : "block",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: matches ? dark ? "#E8E8E8" : "#FFFFFF" : "#FFFFFF" ,
        borderTop: dark ? "none" : "1px solid #E8E8E8",
      }}
    >
      <>{matches ? <img
        src={src}
        style={{ height: "80%", padding: matches ? 24 : 0, maxHeight: 400, paddingRight : matches ? "": 30,paddingLeft : matches ? "": 30, paddingTop: matches ? "" : 30, 
        paddingBottom : matches ? "" : 30}}
        alt="logo" />: 
        <div  style={{
          display:  "flex" ,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: matches ? dark ? "#E8E8E8" : "#FFFFFF" : "#FFFFFF" ,
          borderTop: dark ? "none" : "1px solid #E8E8E8",
        }}>
        <img
        src={src}
        style={{ height: "80%", padding: matches ? 24 : 0, maxHeight: 400, paddingTop: matches ? "" : 30, 
        paddingBottom : matches ? "" : 30}}
        alt="logo" /> </div>}
        </> 
      <div
        style={{
          width: "-webkit-fill-available",
          padding: matches ? 24 : "",
          height: "100%",
          paddingTop:  matches ? "20%" : "",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          margin: matches ? "" : 30,
          marginTop : matches ? "" : 0
        }}
      >
        <Text color="#F0A202" fontWeight="bold" style={{fontSize: matches ? "24px" : "5vw"}} >
          {title}
        </Text>
        <Text fontFamily="Roboto" fontWeight="light" style={{fontSize: matches ? "20px" : "4vw", marginTop : matches ? "unset" : 0, marginBottom : matches ? "unset" :0}}>
          {body}
        </Text>
      </div>
    </div>
  );
}
