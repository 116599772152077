import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Text from "../Text/Text";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 700,
    width: "max(40vw, 500px)",
    background: "#fff",
    borderRadius: 10,
    minHeight: 187,
    margin: "1rem",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
  },
  rootMobile: {
    maxWidth: 564,
    width: "100%",
    background: "#fff",
    borderRadius: 10,
    minHeight: 100,
    boxShadow: "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
    marginTop: 20
  },
  imageBackground: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
});

function BlogPost({ image, title, date }) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();
  const { language } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Link to={`/${language}/sample4`} style={{ textDecoration: "none" }}>
      <div
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={matches ? classes.root : classes.rootMobile}
      >
        <div
          style={{
            width: matches ? "15vw" : "20vw",
            maxWidth: matches ? 300 : 200,
            minHeight: matches ? 187 : "100%",
            minWidth: matches ? 187 : 115,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            src={image}
            className={clsx(classes.imageBackground, {
              [classes.hover]: hover,
            })}
            style={{
              opacity: 1.0,
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            padding: 20,
            flexDirection: "column",
          }}
        >
          <Text color="#444444"  fontSize={matches ? 24 :"4vw"} style={{ margin: 0, padding: 0 , fontWeight: 500}}>
            {date}
          </Text>
          <Text
            color="#F0A202"
            fontWeight="bold"
            fontFamily="Roboto"
            fontSize={matches ? 36 :"4vw"}
            style={{ margin: 0, padding: 0, width: matches ? "70%" :"100%" }}
          >
            {title}
          </Text>
        </div>
      </div>
    </Link>
  );
}

export default BlogPost;
