import React from "react";
import Text from "../Text/Text";
import { Slide, Fade } from "react-awesome-reveal";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function LogoCascade({ noBorder, header, logos, withMarginTop,withMarginLeft }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          borderTop: noBorder ? "none" : "1px solid #707070",
          width: matches ? "-webkit-fill-available" : "100%",
          margin: matches ? 40 : 0,
          padding: matches ? 40 : 0,
          display: "flex",
          flexDirection: "column",
          marginTop : withMarginTop ? 100 : "unset",
        }}
      >
        <Text
          color="#F3A83F"
          textTransform="uppercase"
          fontWeight="bold"
          style={{ zIndex: matches ? 9999 : 1 , justifyContent: matches ? "unset" : "center",fontSize: matches ? "unset" :"5vw",
                   alignItems: matches ? "unset" : "center", display : matches ? "unset" : "flex", textAlign: matches ? "unset" :"center",
                   marginTop : matches ? "unset" : 30, marginBottom : matches ? "unset" : 30, marginLeft : withMarginLeft ? 13 : "unset"
          }}
        >
          {header}
        </Text>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: matches ? "flex-start" : "center",
            alignItems: "center",
            marginBottom: matches ? "unset" : 30
          }}
        >
          <Fade triggerOnce={true} duration={2000}>
            {logos?.map((x) => (
              <Slide direction="down" triggerOnce={true} duration={2000}>
                {x}
              </Slide>
            ))}
          </Fade>
        </div>
      </div>
    </div>
  );
}
