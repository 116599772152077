import { Divider, ListItemButton, ListItemIcon,Grid ,Stack,ListItem} from "@mui/material";
import { ActionIconsContainerDesktop, ActionIconsContainerMobile, MyList } from "../styles/appbar";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Colors } from "../styles/theme";
import Footer from "../../components/Footer/Footer";
import clsx from "clsx";
import React from "react";
import { Link, useParams,matchPath,useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFFFFF",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #707070",
  },
  text: {
    color: "#989696",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    fontSize:"4vw"
    // margin: 100,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#989696",
  },
  pStyle:{
    whiteSpace: "pre-wrap",
  },
  hrs:{ 
  
    border:         "none",
    borderLeft:    "1px solid",
    height:         "3vh",
    width:          "15px",  
    margin: "unset"     
}
});
export default function Actions({ matches }) {

  const Component = matches ? ActionIconsContainerMobile : ActionIconsContainerDesktop;
  const classes = useStyles();
  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";

    const spacer = <div style={{ width: 10 }} />;
    const spacerHeight = <div style={{ height: 10 }} />;

  return (
    <Component>
      <MyList style={{borderTop : "1px solid #989696"}}> 
            <p className={classes.text}>
                © PT Sarana Yukti Bandhana
            </p>
            <p className={classes.text}>
              <a className={clsx([classes.text, classes.link])} href="https://www.linkedin.com/company/syb-online/">
                    {language == "en" ? "Follow Us" : "Ikuti Kami"}
              </a>
              {spacer}
              <Link className={clsx([classes.text,classes.link])} to={`/${language}/contact`}>
                    {language == "en" ? "Contact" : "Kontak"}
              </Link>
            </p>
      </MyList>
    </Component>
  );
}
