import { AppbarContainer,AppbarHeader} from "../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
// import SearchIcon from "@mui/icons-material/Search";
// import Actions from "./actions";
import { IconButton,Grid,Box,Typography,ListItem,List ,styled} from "@mui/material";
import { useUIContext } from "../context/ui";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import LanguageToggleId from "../LanguageToggle/LanguageToggle_id";
import { matchPath } from "react-router";
import { useLocation } from "react-router";
import React from "react";
// import NavBarLink from "../NavBarLink/NavBarLink";
import logo from "../../images/Logos/SYBLogo.svg";
import { Link } from "react-router-dom";
// import NavLogo from "../Svg/NavLogo";

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Divider from '@mui/material/Divider';
import {DrawerWidth} from "../styles/theme";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppMenu from "../drawer/menu";

export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();

  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";
  const Toggle = language == "en" ? LanguageToggle : LanguageToggleId;

  const [state, setState] = React.useState({
    left: false,
  });

  const [open, setOpen] = React.useState(false);

  const MiddleDivider = styled((props) => (
    <Divider variant="middle" {...props} />
  ));

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = () => {
    setOpen(!open);
  };

   const handleClose = () => {
    this.setState({open: false})
  }


  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //    <List style={{width: DrawerWidth }}>
  //         <ListItem onClick={handleClick}>
  //             <ListItemText primary={language == "en" ? "About Us" : "Tentang Kami"}  />
  //             {open ? <ExpandLess /> : <ExpandMore/>}
  //         </ListItem>
  //         <Collapse in={open} timeout="auto" unmountOnExit>
  //           <List component="div" disablePadding>
  //             <ListItem component={Link} to={`/${language}/visionvalues`} >
  //               <ListItemButton  sx={{pl:4}}>
  //                 <ListItemText primary={language == "en" ? "Vision and Values" : "Visi dan Nilai-Nilai"} style={{ color: '#000000' }}/>
  //               </ListItemButton>
  //             </ListItem>
  //             <ListItem component={Link} to={`/${language}/ourjourney`} >
  //               <ListItemButton sx={{pl:4}}>
  //                 <ListItemText primary={language == "en" ? "Our Journey" : "Perjalanan Kami"} style={{ color: '#000000' }}/>
  //               </ListItemButton>
  //             </ListItem>
  //           </List>
  //         </Collapse>
  //         <MiddleDivider />
  //         <ListItem component={Link} to={`/${language}/capabilities`} >
  //           <ListItemButton>
  //             <ListItemText primary= {language == "en" ? "Capabilities" : "Kemampuan"} style={{ color: '#000000' }} />
  //           </ListItemButton>
  //         </ListItem>
  //         <MiddleDivider />
  //         <ListItem component={Link} to={`/${language}/clients`} >
  //           <ListItemButton>
  //             <ListItemText primary= {language == "en" ? "Our Clients" : "Klien Kami"} style={{ color: '#000000' }} />
  //           </ListItemButton>
  //         </ListItem>
  //         <MiddleDivider />
  //         <ListItem component={Link} to={`/${language}/news`} >
  //           <ListItemButton>
  //             <ListItemText primary=  {language == "en" ? "News" : "Berita"} style={{ color: '#000000' }}/>
  //           </ListItemButton>
  //         </ListItem>
  //         <MiddleDivider />
  //         <ListItem component={Link} to={`/${language}/contact`} >
  //           <ListItemButton>
  //             <ListItemText primary= {language == "en" ? "Contact" : "Kontak"} style={{ color: '#000000' }}/>
  //           </ListItemButton>
  //         </ListItem>
  //       </List>
  //   </Box>
  // );

  return (
    <AppbarContainer>
        <List style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <ListItem style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              {/* <IconButton onClick={() => setDrawerOpen(true)}>
                  <MenuIcon style={{fontSize:"50px", marginRight:"10px"}}/>
              </IconButton> */}
              
              {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon style={{fontSize:"40px", marginRight:"10px",color: "#000000",opacity: "54%"}} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}>
                        <AppMenu toggleDrawer={toggleDrawer} />
                  </Drawer>
                </React.Fragment>
              ))}
              <Link to={`/${language}`}>
                  <img src={logo} style={{ height: "5vh" ,objectFit: "cover"}}/>
              </Link>
            </ListItem>
            {/* <ListItem style={{justifyContent: "center"}}>
                <Link to={`/${language}`} >
                      <img src={logo} style={{ height: "5vh" ,objectFit: "cover"}}/>
                </Link>
            </ListItem> */}

            <ListItem style={{width: '50%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',marginRight: "0px"}}>
              <Link  style={{justifyContent: "flex-end"}} to={`/${language == "en" ? "id" : "en"}/`}>
                  <Toggle scale={0.7} />
              </Link>
            </ListItem>
          </List>
      {/* <Actions matches={matches} /> */}
    </AppbarContainer>
  );
}
