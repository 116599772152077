import * as React from "react";
import Text from "../Text/Text";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function TileComponent() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fill: "#fff",
          fontSize: matches ? 100 : "20vw",
          fontFamily: "Montserrat-Bold, Montserrat",
          fontWeight: 700,
          padding: 0,
          margin: 0,
          textDecoration: "none",
        }}
      >
        10K+
      </Text>
      <Text
        style={{
          fill: "#fff",
          fontSize: matches ? 36 : "10vw",
          fontFamily: "Montserrat-Bold, Montserrat",
          fontWeight: 700,
          padding: 0,
          margin: 0,
          textDecoration: "none",

        }}
      >
        AGENTS
      </Text>
    </div>
  );
}

export default TileComponent;
