import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.webp";
// import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ? 500 : "unset"}>
        <>{matches ? <Heading color="#FFFFFF">Ruang Pres</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0, fontFamily:"Roboto",fontWeight : "bold"}}>Ruang Pres</p> }</>  
          <>{matches ? <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
            Pengumuman terbaru, wawasan industri dan perspektif dari SYB
          </Heading> :""}</>
        </Banner>
      </div>
      <>{matches ? "" :<p style={{fontWeight:300,color:"#000",fontSize:"4vw", margin:30, marginBottom: 20}}> Pengumuman terbaru, wawasan industri dan perspektif dari SYB </p>}</> 
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" : "100%" ,
              display: "flex",
              flexDirection: "column",
              marginBottom:matches ? "unset" : 48
            }}
          >
            <>{matches ?  <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Grup GoTo Indonesia mengumpulkan lebih dari $1,3 miliar dalam penutupan pra-IPO pertama
            </Heading> : <p style={{color:"#1B297C",margin: 0,marginBottom: 30, fontSize:"8vw",fontFamily : "Roboto",fontWeight :"bold"}}>
            Grup GoTo Indonesia mengumpulkan lebih dari $1,3 miliar dalam penutupan pra-IPO pertama
            </p> }</>
            <>{matches ?  <Heading variant="h4" style={{ marginBottom: "1rem" ,color: "#444444",fontWeight: 500 }}>
              11 November 2021
            </Heading> :  <p style={{fontSize:"5vw",color: "#444444",fontWeight: 500,margin: 0,marginBottom: 30 }} >11 November 2021</p>}</>
           
            <div className={classes.textSectionWrapper}>
            <>{matches ? <div className={classes.textSectionBorder} /> : ""}</>
              <div className={matches ? classes.textSection :  classes.textSectionMobile}>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
                Startup Grup GoTo yang berbasis di Indonesia hari ini mengumumkan telah mendapatkan lebih dari $1,3 miliar pada penutupan pertama pendanaan pra-IPO.
                </Text>
              </div>
            </div>
            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Putaran terbaru dipimpin oleh Abu Dhabi Investment Authority (ADIA), dengan partisipasi dari Avanda Investment Management, Fidelity International, Google, Permodalan Nasional Berhad (PNB), Primavera Capital, SeaTown Master Fund, Temasek, Tencent dan Ward Ferry.
            Investor lebih lanjut diharapkan untuk bergabung dalam penutupan terakhir pendanaan dalam beberapa minggu mendatang.

            Grup GoTo akan menggunakan dana tersebut untuk mendorong pertumbuhannya dan meningkatkan pelayanan pelanggan.

            Perusahaan menolak berkomentar tentang penilaiannya.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Menurut laporan media, penilaian Grup GoTo diperkirakan diantara $28,5 miliar dan $30 miliar.

            Raksasa Ride-Hailing Gojek dan perusahaan e-commerce Tokopedia, dua startup terbesar di Indonesia, bergabung untuk membentuk Grup GoTo pada Mei tahun 2021. Grup GoTo menciptakan sinergi dengan merek Gojek, Tokopedia dan Goto Financial di seluruh industri transportasi, e-commerce, pengiriman makanan dan bahan makanan, logistic dan industri jasa keuangan.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            Grup GoTo, grup teknologi terbesar di negara Asia Tenggara, 
            sedang mempersiapkan dual listing di New York dan Jakarta akhir tahun 2021.

            Grup GoTo mengklaim telah menghasilkan lebih dari 1,8 miliar transaksi pada tahun 2020, dengan nilai transaksi bruto lebih dari $22 miliar, berkontribusi lebih dari 2% terhadap Produk Domestik Bruto (PDB) Indonesia. 
            Indonesia memiliki PDB lebih dari $1 triliun dengan populasi 270 juta.
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,  fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            “Indonesia dan Asia Tenggara adalah beberapa pasar dengan prtumbuhan paling menarik di dunai, dan dukungan yang kami peroleh menunjukkan kepercayaan yang dimiliki investor terhadap ekonomi digital yang berkembang pesat di Kawasan ini dan posisi kami sebagai pemimpin pasar,” kata Andre Soelistyo, CEO dari Grup GoTo. 
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,  fontSize: matches ? "unset" : "4vw", margin: matches ? "unset" : 0, marginBottom: matches ? "unset" : 30}}>
            “Permintaan konsumen dibuka oleh pertumbuhan adopsi digital yang telah membawa banyak pengguna baru online. 
             Akibatnya, permintaan akan layanan kami terus meningkat, ditegaskan oleh komitmen kami untuk terus memberikan pilihan, nilai dan kenyamanan kepada pengguna di seluruh ekosistem.”
            </Text>

            <Text style={{color: "#444444",fontWeight: 500,  fontSize: matches ? "unset" : "4vw",  margin: matches ? "unset" : 0}}>
            “Kami melihat peluang pertumbuhan sekuler untuk Indonesia dan GoTo di seluruh e-commerce, on-demand mobility dan fintech – semua segmen dimana Primavera memiliki pengalaman investasi yang luas. Kami senang bermitra dan tumbuh Bersama GoTo, memanfaatkan keahlian dan sumber daya kami yang berguna bagi manajemen,” Michael Woo, Managing Director Primavera Capital Group.            </Text>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
