import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import Text from "../Text/Text";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    minWidth: "max(40vw, 500px)",
    background: "#fff",
    borderRadius: 10,
    height: "15vw",
    maxHeight: 300,
    minHeight: 187,
    margin: "1rem",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
  },
  rootMobile: {
    maxWidth: 800,
    minWidth: 200,
    background: "#fff",
    borderRadius: 10,
    height: "15vw",
    maxHeight: 300,
    minHeight: 187,
    margin: "1.25rem",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
  },
  imageBackground: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
});

function Card({ image, children }) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={matches ? classes.root : classes.rootMobile}
    >
      <div
        style={{
          width: "15vw",
          maxWidth: 300,
          height: "15vw",
          maxHeight: 300,
          minHeight: 187,
          minWidth: 187,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          className={clsx(classes.imageBackground, { [classes.hover]: hover })}
          style={{
            opacity: 1.0,
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: matches ? "8%" : "16px",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Card;
