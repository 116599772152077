import React from "react";
import { Link } from "react-router-dom";
import "./NavBarLink.css";

export default function NavBarLink({ children, to, logo = false }) {
  return (
    <Link className={logo ? "logo" : "link"} to={to}>
      {children}
    </Link>
  );
}
