import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/News/Banners/banner.webp";
import tile1 from "../../images/News/Tiles/1.webp";
import tile2 from "../../images/News/Tiles/2.webp";
import tile3 from "../../images/News/Tiles/3.webp";
import tile4 from "../../images/News/Tiles/4.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import BlogPost from "../../components/BlogPost/BlogPost";
import BlogPost2 from "../../components/BlogPost/BlogPost_2";
import BlogPost3 from "../../components/BlogPost/BlogPost_3";
import BlogPost4 from "../../components/BlogPost/BlogPost_4";
import { Grid,useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../components/appbar/actions";


const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  rowMobile: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function News({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={matches ? 500 : "unset"}>
        <>{matches ? <Heading color="#FFFFFF">Ruang Pres</Heading> :   
        <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Ruang Pres</p> }</>  
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ? 100 : 30,
              width: matches ? "70%" :"100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginBottom : matches ? "unset" : 20

            }}
          >
            <div className={classes.textSectionWrapper}>
              <>{matches ? <div className={classes.textSectionBorder} /> : ""}
              </>
              
              <div className={matches ? classes.textSection : classes.textSectionMobile}>
              <Text style={{color: "#444444",fontWeight: 500, fontSize: matches ? "unset" : "4vw",marginTop: matches ? "unset" : 0, marginBottom: matches ? "unset" :0 }}>
                  Pengumuman terbaru, wawasan industri dan perspektif dari SYB.
                  Pelajari isu dan inovasi terbaru yang mendorong masa depan
                  industri pembayaran digital.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={matches ? classes.row : classes.rowMobile}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: matches? 40 :   0,
                padding: matches ? "0px 40px 80px 40px" : "0",
                margin : matches ? "unset" : 30,
                display: "flex",
                flexDirection: "column",
                marginBottom: matches ? "unset" : 48
              }}
            >

              <>{matches ?<Heading variant="h3" style={{ margin: "1rem" }}>
                Publikasi
              </Heading>:   
               <p style={{color:"#1B297C", fontSize: "8vw",padding: 0, margin: "unset",  fontFamily:"Roboto",fontWeight : "bold"}}>Publikasi</p> }</>  
              
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <BlogPost
                  image={tile1}
                  date="21 Desember 2021"
                  title="BI-Fast Diresmikan, Biaya Transfer Antarbank Jadi Rp 2.500"
                />
                <BlogPost2
                  image={tile2}
                  date="11 November 2021"
                  title="Grup GoTo Indonesia mengumpulkan lebih dari $1,3 miliar dalam penutupan pra-IPO pertama"
                />
                <BlogPost3
                  image={tile3}
                  date="08 Oktober 2021"
                  title="Sah! Akulaku Jack Ma Resmi Jadi Pengendali Bank Neo"
                />
                <BlogPost4
                  image={tile4}
                  date="21 Oktober 2021"
                  title="Jadi Pengendali Bank Bisnis, Kredivo Suntik Rp 439,69 Miliar"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions />}</>
    </Grid>
  );
}
