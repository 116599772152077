import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/Contact/Banners/banner.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import AddressTile from "../../components/AddressTile/AddressTile";
import poster from "../../images/Contact/Posters/poster.webp";
import Text from "../../components/Text/Text";
import ContactForm from "../../components/Forms/ContactForm/ContactForm";
import Call from "../../images/Contact/Icons/call.svg";
import Email from "../../images/Contact/Icons/email.svg";
import Fax from "../../images/Contact/Icons/fax.svg";
import { Grid,useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Actions from "../../components/appbar/actions";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexWrap: {
    display: "flex",
    width: "100%",
    minHeight: 500,
    flexWrap: "wrap",
  },
  flexWrapMobile: {
    display: "flex",
    width: "100%",
    minHeight: 630,
    flexWrap: "wrap",
    paddingBottom:48
  },
  flexWrapSection: {
    flex: 1,
    minWidth: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexWrapSectionMobile: {
    flex: 1,
    minWidth: 360,
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
});

function renderMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.2969914726777!2d106.806353!3d-6.224516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1245f82f95e6da3d!2sSarana%20Yukti%20Bandhana.%20PT!5e0!3m2!1sen!2sid!4v1635861414578!5m2!1sen!2sid"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  );
}

const contactMethods = [
  {
    name: "Call Centre",
    value: <Text color="#000000" style={{ margin: 0, padding: 0 }}>(62-21) 252 4172</Text>,
    image: Call,
  },
  {
    name: "Fax",
    value: <Text color="#000000" style={{ margin: 0, padding: 0 }}>(62-21) 251 4173</Text>,
    image: Fax,
  },
  {
    name: "Email",
    value: (
      <Text color="#000000" style={{ margin: 0, padding: 0 }}>
        <a href="mailto:info@saranaonline.com">info@saranaonline.com</a>
      </Text>
    ),
    image: Email,
  },
];

function ContactMethod({ name, imageSrc, children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 16,
      }}
    >
      <div style={{ width: 120, display: "flex", justifyContent: "center" }}>
        <img src={imageSrc} style={{ height: "100%" }} />
      </div>
      <div>
        <Text
          fontWeight="bold"
          color="#1B297C"
          style={{ margin: 0, padding: 0 }}
        >
          {name}
        </Text>
        {children}
      </div>
    </div>
  );
}

export default function News({ scrollToRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <div ref={scrollToRef}>
      <Banner background={banner} height={matches ? 500 : "unset"}>
        <>{matches ? <Heading color="#FFFFFF">Contact Us</Heading> :   <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Contact Us</p> }</>
        </Banner>
      </div>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                margin: matches ? 40 : 30,
                paddingInline: matches ? 40 : 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <>{matches ?  <Heading variant="h2" style={{ margin: "0.5rem" }}>
              Get in Touch
              </Heading> :   <p style={{color: "#293EBB", fontSize: "8vw",padding: 0, margin: "0rem",  fontFamily:"Roboto",fontWeight : "bold"}}>Get in Touch</p> }</>
             
            </div>
            <div className={classes.flexWrap}>
              <div className={matches? classes.flexWrapSection : classes.flexWrapSectionMobile} style={{ flex: 3 }}>
                <ContactForm />
              </div>
              <div className={matches? classes.flexWrapSection : classes.flexWrapSectionMobile} style={{ flex: 2 }}>
                <div
                  className={classes.flexColumn}
                  style={{ alignSelf: "flex-start", paddingTop:matches? 40 :10 , paddingBottom: matches ? 0 : 20 }}
                >
                  {contactMethods.map((c) => (
                    <ContactMethod
                      key={c.name}
                      name={c.name}
                      imageSrc={c.image}
                    >
                      {c.value}
                    </ContactMethod>
                  ))}
                </div>
              </div>
            </div>
            <div className={matches ? classes.flexWrap : classes.flexWrapMobile}>
              <div className={matches? classes.flexWrapSection : classes.flexWrapSectionMobile}>
                <AddressTile image={poster}>
                  <Text
                    color="#FFFFFF"
                    fontSize={matches ? 28 : "6vw"}
                    style={{
                      margin: matches ? 0 : 30,
                      padding: matches ? "5% 10%" : 16,
                      background: "rgba(0, 0, 0, 0.4)",
                      fontWeight : matches ? "unset" : 400
                    }}
                  >
                    Sequis Centre Building
                    <br />
                    9th Floor
                    <br />
                    Jl. Jend. Sudirman Kav. 71
                    <br />
                    Jakarta 12190
                    <br />
                    Indonesia
                  </Text>
                </AddressTile>
              </div>
              <div className={matches? classes.flexWrapSection : classes.flexWrapSectionMobile}>{renderMap()}</div>
            </div>
          </div>
        </div>
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
