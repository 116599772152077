import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} {...props}>
      <g data-name="Group 78">
        <g data-name="Group 77" transform="translate(-1580 -4498)">
          <circle
            data-name="Ellipse 32"
            cx={30}
            cy={30}
            r={30}
            transform="translate(1580 4498)"
            fill="#f0a202"
          />
          <g
            data-name="Group 76"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={4}
          >
            <path data-name="Line 30" d="M1597.5 4535.5l12.5-15" />
            <path data-name="Line 36" d="M1622.5 4535.5l-12.5-15" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
