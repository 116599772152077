import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "#fff",
    height: "100%",
    margin: 0,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
    position: "relative",
  },
  imageBackground: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
});

function AddressTile({ image, children }) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.root}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
      <img
        src={image}
        className={clsx(classes.imageBackground, { [classes.hover]: hover })}
        style={{
          opacity: 1.0,
        }}
      />
    </div>
  );
}

export default AddressTile;
