import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/OurJourney/Banners/banner.webp";
import tile1 from "../../images/OurJourney/Tiles/1.webp";
import tile2 from "../../images/OurJourney/Tiles/2.webp";
import tile3 from "../../images/OurJourney/Tiles/3.webp";
import tile4 from "../../images/OurJourney/Tiles/4.webp";
import tile5 from "../../images/OurJourney/Tiles/5.webp";
import tile6 from "../../images/OurJourney/Tiles/6.webp";
import tile7 from "../../images/OurJourney/Tiles/7.webp";
import tile8 from "../../images/OurJourney/Tiles/8.webp";
import tile9 from "../../images/OurJourney/Tiles/9.webp";
import tile10 from "../../images/OurJourney/Tiles/10.webp";
import tile11 from "../../images/OurJourney/Tiles/11.webp";
import tile12 from "../../images/OurJourney/Tiles/12.webp";
import tile13 from "../../images/OurJourney/Tiles/13.webp";
import tile14 from "../../images/OurJourney/Tiles/14.webp";
import tile15 from "../../images/OurJourney/Tiles/15.webp";
import tile16 from "../../images/OurJourney/Tiles/16.webp";
import tile17 from "../../images/OurJourney/Tiles/17.webp";
import tile18 from "../../images/OurJourney/Tiles/18.webp";
import tile19 from "../../images/OurJourney/Tiles/19.webp";
import tile20 from "../../images/OurJourney/Tiles/20.webp";
import tile21 from "../../images/OurJourney/Tiles/21.webp";
// import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import Cascade from "../../components/Cascade/Cascade";
import Milestone from "../../components/Milestone/Milestone";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import Actions from "../../components/appbar/actions";
import { margin } from "polished";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionMobile: {
    padding: "0px 0px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  textSectionBorderMobile: {
    borderLeft: "0px",
    margin: "0px 0px",
  },
  highlight: {
    fontWeight: "bold",
    color: "#F0A202",
  },
});

const mileStones = [
  <Milestone
    title="2000"
    image={tile1}
    content="We launched our first digital payment platform, PRAQTIS, with PT PLN
  (Persero) and 3 banks, PT Bank Rakyat Indonesia (Persero) Tbk (BRI), PT
  Bank Lippo Tbk (now merged with PT Bank CIMB Niaga Tbk) and PT Bank
  Ekonomi Raharja Tbk (now merged with PT Bank HSBC Indonesia)."
  />,
  <Milestone
    title="2001"
    image={tile2}
    content="We expanded our banking network to 8 banks, including PT Bank Central Asia
  Tbk (BCA), the largest private bank in Indonesia."
  />,
  <Milestone
    title="2002"
    image={tile3}
    content="We relaunched our PLN payment service to issue a single unique customer
  number nationwide. We also expanded our network of banks to 15 banks."
  />,
  <Milestone
    title="2003"
    image={tile4}
    content="We launched our first digital payment platform pilot to agencies and
  non-banking institutions. Concurrently, we launched our first digital bill
  payment application, Online Payment Point (OPP), for recruited agents to
  serve unbanked customers across Indonesia."
  />,
  <Milestone
    title="2004"
    collapsable
    image={tile5}
    content="One of the largest government owned bank in Indonesia, PT Bank Mandiri
  (Persero) Tbk, joined our digital payment ecosystem to receive PLN payment
  services. We also expanded our bill payment category to leasing services
  with the three largest motorcycle leasing companies in Indonesia, namely,
  PT Federal International Finance (FIF) under PT Astra International Tbk,
  PT Bussan Auto Finance (BAF) under the Mitsui Group, as well as PT Summit
  Oto Finance (SOF) and PT Oto Multiartha (OTO) under the Sumitomo Group."
  />,
  <Milestone
    title="2005"
    image={tile6}
    content="PT Bank Maybank Indonesia Tbk, PT Bank Mega Tbk, PT Bank Ganesha Tbk and
  PT Bank Ina Perdana Tbk, joined our digital payment ecosystem to receive
  PLN payment services."
  />,
  <Milestone
    title="2006"
    image={tile7}
    content="Standard Chartered Bank Indonesia and PT Bank HSBC Indonesia joined our
  digital payment ecosystem to receive PLN payment services."
  />,
  <Milestone
    title="2007"
    image={tile8}
    content="PT Bank Sinarmas Tbk joined our digital payment ecosystem to receive PLN
  payment services."
  />,
  <Milestone
    title="2008"
    image={tile9}
    content="We launched our first electronic data capture (EDC) product line for PT
  Federal International Finance (FIF) under PT Astra International Tbk. We
  also launched our first estate management fee payment service for PT Tata
  Mandiri Daerah Lippo Karawaci."
  />,
  <Milestone
    title="2009"
    image={tile10}
    content="We launched our first telecommunication bill payment service with PT
  Finnet Indonesia. During this year, BRI Link joined our digital payment
  ecosystem to receive multibiller payment services."
  />,
  <Milestone
    title="2010"
    image={tile11}
    content="Indonesia’s government owned postal service provider, PT Pos Indonesia
  (Persero), joined our digital payment ecosystem to receive multibiller
  payment services."
  />,
  <Milestone
    title="2011"
    image={tile12}
    content="We launched our first remittance service with one of the largest
  international remittance company, PT Western Union Indonesia. We
  introduced a new billing platform, PaymentKita, for our multibiller
  payment services in PT Bank Mandiri (Persero) Tbk."
  />,
  <Milestone
    title="2012"
    image={tile13}
    content="We launched our first train ticket payment service for PT Kereta Api
  Indonesia (Persero)."
  />,
  <Milestone
    title="2013"
    image={tile14}
    content="We launched our first airline ticket payment service for PT Citilink
  Indonesia."
  />,
  <Milestone
    title="2014"
    collapsable
    image={tile15}
    content="We developed additional self-service terminals beyond electronic data
  capture machines (EDC), such as automated teller machines (ATM), cash
  deposit machines (CDM) and cash recycling machines (CRM). We introduced
  our first tax payment service, Direktorat Jenderal Pajak (DJP), and our
  first pre-paid television payment service for PT Digital Vision Nusantara
  (KVision)."
  />,
  <Milestone
    title="2015"
    image={tile16}
    content="Together with PT Bank Mandiri (Persero) Tbk, we launched a premium payment
  service for Indonesia’s national health insurance provider, BPJS
  Kesehatan. We also launched our first media bill payment service for PT
  Kompas Media Nusantara (Kompas) and PT MNC Kabel Mediacom (MNC Play)."
  />,
  <Milestone
    title="2016"
    image={tile17}
    content="The largest Indonesian e-commerce platform, PT Tokopedia (Tokpedia),
  joined our digital payment ecosystem to receive multibiller payment
  services."
  />,
  <Milestone
    title="2017"
    collapsable
    image={tile18}
    content="We expanded our premium payment service for BPJS Kesehatan to PT Bank
  Central Asia Tbk (BCA), PT Bank Permata Tbk, PT Bank CIMB Niaga Tbk and PT
  Bank Panin Tbk. We ventured into the education sector and implemented a
  tuition payment service for Universitas Muhammadiyah Malang (UMM),
  Universitas Padjajaran (UNPAD) and Institut Teknologi Bandung (ITB). We
  launched our first tax payment service with Property Tax (PBB) Depok."
  />,
  <Milestone
    title="2018"
    collapsable
    image={tile19}
    content="We launched our first electronic money top up service, E-Money Mandiri,
  with PT Pos Indonesia (Persero). We also launched our first internet bill
  payment service with PT Telkom Satelit Indonesia and PT Sampoerna
  Telekomunikasi Indonesia. During this year, Indonesia’s largest
  state-owned pawn broking provider, PT Pegadaian (Persero), joined our
  digital payment ecosystem to receive multibiller payment services."
  />,
  <Milestone
    title="2019"
    collapsable
    image={tile20}
    content="We launched our first e-Wallet top-up service with PT Dompet Anak Bangsa
  (GoPay), PT Visionet Internasional (OVO) and PT Espay Debit Indonesia Koe
  (DANA). We also launched our first Google Play voucher service with PT
  Bank Central Asia Tbk (BCA). We expanded our digital payment ecosystem to
  encompass e-commerce and e-wallets with PT Shopee International Indonesia
  (Shopee), PT Bukalapak.com (Bukalapak), PT Witami Tunai Mandiri
  (Truemoney) and PT Dompet Harapan Bangsa (OY!)."
  />,
  <Milestone
    title="2020"
    image={tile21}
    content="In the midst of the Covid-19 pandemic, SYB was awarded by BPJS Kesehatan
  for processing the largest volume of autodebits for the payment of health
  insurance premiums. During this year, PT Fintek Karya Nusantara (LinkAja),
  joined our digital payment ecosystem to receive multibiller payment
  services."
  />,
];

export default function ClientNetwork({ scrollToRef }) {
  const classes = useStyles();
  const { language } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      <div ref={scrollToRef}>
        <Banner background={banner} height={ matches ? 500 : "unset"}>
        <>{matches ?<Heading color="#FFFFFF">Our Journey</Heading> : 
          <p style={{color:"#FFFFFF", fontSize: "10vw",padding: 30, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Our Journey</p> }</>
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: matches ?  100 : 30,
              marginBottom: matches ? 0 : 30,
              width: matches ?  "70%" : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop : matches ? 100 : 30,
            }}
          >
            <div className={classes.textSectionWrapper}>
            <div className={matches ? classes.textSectionBorder : classes.textSectionBorderMobile} />
            <div className={matches ? classes.textSection : classes.textSectionMobile}>
            <>{matches ? <Heading>Early Beginnings</Heading> :  
              <p style={{color:"#293EBB", fontSize: "8vw",padding: 0, margin: 0,  fontFamily:"Roboto",fontWeight : "bold"}}>Early Beginnings</p> }</>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches? "unset" : "4vw"}}>
                  Our journey began in the year 2000 as SYB launched Indonesia’s
                  first digital payment services platform,{" "}
                  <span className={classes.highlight}>‘PRAQTIS’</span>, with PT
                  Perusahaan Listrik Negara (PLN) (Persero), Indonesia’s sole
                  government electricity distribution company. As the architect
                  of the first nationwide borderless digital payment product,
                  SYB ushered a paradigm shift, which served as the impetus for
                  Indonesia’s digital payment industry.
                </Text>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches? "unset" : "4vw"}}>
                  For more than 20 years, SYB has championed the goal to provide
                  ease and accessibility to the user purchase and collection
                  process by providing seamless links to a vast network of
                  collecting agents. Today, we serve every major market with
                  direct network connections to 40 banks, 500+ corporate
                  partners and 10,000+ agents.
                </Text>
                <Text style={{color: "#444444",fontWeight: 500, fontSize: matches? "unset" : "4vw"}}>
                  Our journey reflects consistent promise for{" "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>ingenuity</span>
                  </Link>
                  ,{" "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>innovation</span>
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>integrity</span>
                  </Link>{" "}
                  as we relentlessly strive to provide our customers with
                  unparalleled opportunities in an inclusive digital economy. In
                  tangent with the inexorable march of technology, we continue
                  to deliver both compelling and creative digital payment
                  solutions to drive financial efficiency across Asia.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Cascade items={mileStones} />
      </section>
      <>{matches ? <Footer language="en" /> : <Actions/> }</>
    </Grid>
  );
}
