import React, { useState  } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Box,styled} from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { matchPath } from "react-router";
import { useLocation } from "react-router";
import { hasChildren } from "./menuutils";
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { MenuItems } from "../drawer/menuitem";
import {DrawerWidth} from "../styles/theme";

export default function IMARSMenu(props) {
    return MenuItems.map((item, key) => (
        <MenuItem key = {key} item= {item} toggleDrawer={props.toggleDrawer} />
        
      ));
}

const MenuItem = ({ item, toggleDrawer }) => {
    const MenuComponent = hasChildren(item) ? MultiLevel : "";
    return <MenuComponent item={item} toggleDrawer={toggleDrawer} />;
  };

// const SingleLevel = ({ toggleDrawer }) => {
//     const MiddleDivider = styled((props) => (
//         <Divider variant="middle" {...props} />
//       ))``;
//     const [toggle, setToggle] = React.useState(toggleDrawer);
//     const location = useLocation();

//     const language = matchPath(location.pathname, {
//         path: "/id",
//         exact: false,
//         strict: false,
//     })
//     ? "id"
//     : "en"; 
//     return (
//         <><ListItem component={Link} to={`/${language}/capabilities`}>
//             <ListItemButton>
//                 <ListItemText primary={language == "en" ? "Capabilities" : "Kemampuan"} style={{ color: '#000000' }} />
//             </ListItemButton>
//         </ListItem><MiddleDivider /><ListItem component={Link} to={`/${language}/clients`}>
//                 <ListItemButton>
//                     <ListItemText primary={language == "en" ? "Our Clients" : "Klien Kami"} style={{ color: '#000000' }} />
//                 </ListItemButton>
//             </ListItem><MiddleDivider /><ListItem component={Link} to={`/${language}/news`}>
//                 <ListItemButton>
//                     <ListItemText primary={language == "en" ? "News" : "Berita"} style={{ color: '#000000' }} />
//                 </ListItemButton>
//             </ListItem><MiddleDivider /><ListItem component={Link} to={`/${language}/contact`}>
//                 <ListItemButton>
//                     <ListItemText primary={language == "en" ? "Contact" : "Kontak"} style={{ color: '#000000' }} />
//                 </ListItemButton>
//             </ListItem></>
       
//     );
// };  

const MultiLevel = ({ item,toggleDrawer }) => {
    // const { items: children } = item;
    
    const [open, setOpen] = useState(false);
    const MiddleDivider = styled((props) => (
        <Divider variant="middle" {...props} />
      ))``;
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    const location = useLocation();

    const language = matchPath(location.pathname, {
        path: "/id",
        exact: false,
        strict: false,
    })
    ? "id"
    : "en"; 
 
    return (
      <React.Fragment>
         {/* <Box */}
            {/* onClick={toggleDrawer("left", true)}> */}
         <List style={{width: DrawerWidth }}>
            <ListItem onClick={handleClick}>
              <ListItemText primary={language == "en" ? "About Us" : "Tentang Kami"}  />
              {open ? <ExpandLess /> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to={`/${language}/visionvalues`}  onClick={toggleDrawer("left", false)} >
                <ListItemButton  sx={{pl:4}}>
                  <ListItemText primary={language == "en" ? "Vision and Values" : "Visi dan Nilai-Nilai"} style={{ color: '#000000' }}/>
                </ListItemButton>
              </ListItem>
              <ListItem component={Link} to={`/${language}/ourjourney`}  onClick={toggleDrawer("left", false)}>
                <ListItemButton sx={{pl:4}}>
                  <ListItemText primary={language == "en" ? "Our Journey" : "Perjalanan Kami"} style={{ color: '#000000' }}/>
                </ListItemButton>
              </ListItem>
            </List>
             </Collapse>
             <MiddleDivider />
             <ListItem component={Link} to={`/${language}/capabilities`}  onClick={toggleDrawer("left", false)}>
            <ListItemButton>
                <ListItemText primary={language == "en" ? "Capabilities" : "Kemampuan"} style={{ color: '#000000' }} />
            </ListItemButton>
             </ListItem>
             <MiddleDivider />
             <ListItem component={Link} to={`/${language}/clients`}  onClick={toggleDrawer("left", false)}>
            <ListItemButton>
                <ListItemText primary={language == "en" ? "Our Clients" : "Klien Kami"} style={{ color: '#000000' }} />
            </ListItemButton>
            </ListItem>
            <MiddleDivider />
            <ListItem component={Link} to={`/${language}/news`}  onClick={toggleDrawer("left", false)}>
            <ListItemButton>
                <ListItemText primary={language == "en" ? "News" : "Berita"} style={{ color: '#000000' }} />
            </ListItemButton>
            </ListItem>
            <MiddleDivider />
            <ListItem component={Link} to={`/${language}/contact`}  onClick={toggleDrawer("left", false)}>
                <ListItemButton>
                    <ListItemText primary={language == "en" ? "Contact" : "Kontak"} style={{ color: '#000000' }} />
                </ListItemButton>
            </ListItem>
        </List>
        {/* </Box> */}
      </React.Fragment>
    );
  };
  