import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Container, Typography, Box, Stack, Grid, Button } from "@mui/material";
import Appbar from "./components/appbar";
import { UIProvider } from "./components/context/ui";

ReactDOM.render(
  <React.StrictMode>
    {/* <Container
        disableGutters
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}>
        <Stack>
      <UIProvider>
      <Appbar /> */}
    <App />
     {/* </UIProvider>
      </Stack>
      // </Container> */}
  </React.StrictMode>,
  document.getElementById("root")
);
