import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
export default function Cascade({ items,  }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "-webkit-fill-available",
          margin : matches ? 40 : 30, 
          padding : matches ? 40 : 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {items?.map((x) => (
            <Slide
              direction="down"
              triggerOnce={true}
              duration={1500}
              fraction={0.1}
            >
              <Fade duration={2000} triggerOnce={true}>
                {x}
              </Fade>
            </Slide>
          ))}
        </div>
      </div>
    </div>
  );
}
