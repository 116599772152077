import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Text from "../Text/Text";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const fontSize = 16;
const linesToShow = 7;
const lineHeight = 1.4;

const useStyles = makeStyles({
  root: {
    background: "#fff",
    // height: "15vw",
    height : "450px",
    margin: "1rem",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  rootMobile: {
    background: "#fff",
    height: 340,
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "1.25rem",
    marginLeft: "0rem"
  },
  expand: {
    height: 550,
  },
  imageBackground: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
  text: {
    maxWidth: "400px",
    margin: "0 auto",
    lineHeight: 1.4,
    fontSize: fontSize,
  },
  textMobile: {
    maxWidth: "475px",
    margin: "0 auto",
    lineHeight: 1.4,
    fontSize: "4vw",
  },
  collapsed: {
    display: "block" /* Fallback for non-webkit */,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: fontSize * lineHeight * linesToShow /* Fallback for non-webkit */,
    WebkitLineClamp: linesToShow,
  },
  marginsDouble : {
    width: "-webkit-fill-available",
    marginInline:0,
    padding:"unset",
    display: "flex",
    flexDirection: "column",
    // marginTop: 30,
    // marginBottom : 30
  }, 
  marginsBottom : {
    width: "-webkit-fill-available",
    marginInline: 0,
    padding:"unset",
    display: "flex",
    flexDirection: "column",
    // marginBottom : 30
  }
});

function Service({ image, title, collapsable, content, marginSet }) {
  const [hover, setHover] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  function handleClick() {
    if (collapsable) {
      setExpand(!expand);
    }
  }

  return (
    <>{matches ? <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(matches ? classes.root : classes.rootMobile,{ [classes.expand]: expand })}
    >
      <div
        style={{
          width: matches ? "100%" : "100%",
          height: 200, 
          maxHeight: 200 ,
          minHeight: 200 ,
          borderRadius: 16,
          boxShadow:
            "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={image}
          className={clsx(classes.imageBackground, { [classes.hover]: hover })}
          style={{
            opacity: 1.0,
          }}
        />
      </div>
      <div
        style={{
          padding: matches ? 16 : 0,
          cursor: collapsable ? "pointer" : "auto",
          textAlign: matches ? "center" : "start",
        }}
        onClick={handleClick}
      >
        <Text
          color="#F0A202"
          fontSize={matches ? 18 : "5vw"}
          fontWeight="bold"
          style={{ height: matches ? "4.5em" : "unset" }}
        >
          {title}
        </Text>
        <p
          className={clsx(matches ?classes.text : classes.textMobile, {
            [classes.collapsed]: collapsable && !expand,
          })}
        >
          {content}
        </p>
      </div>
    </div>:  <div  className={marginSet ? classes.marginsBottom : classes.marginsDouble  }             
    style={{
       width: "100%",
      //  maxWidth: 400,
      //  minWidth: 400,
       display: "flex",
       justifyContent: "left",
       // margin: matches ? "40px 0px" : "unset",
     }} >
 <div
   onMouseOver={() => setHover(true)}
   onMouseLeave={() => setHover(false)}
   className={clsx(matches ? classes.root : classes.rootMobile,{ [classes.expand]: expand })}
 >
   <div
     style={{
       width: matches ? "100%" : "100%",
       height: 200, 
       maxHeight: 200 ,
       minHeight: 200 ,
       borderRadius: 16,
       boxShadow:
         "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
       position: "relative",
       overflow: "hidden",
       display: "flex",
       justifyContent: "left",
       alignItems: "left",
     }}
   >
     <img
       src={image}
       className={clsx(classes.imageBackground, { [classes.hover]: hover })}
       style={{
         opacity: 1.0,
       }}
     />
   </div>
   <div
     style={{
       padding: matches ? 16 : 0,
       cursor: collapsable ? "pointer" : "auto",
       textAlign: matches ? "center" : "start",
     }}
     onClick={handleClick}
   >
     <Text
       color="#F0A202"
       fontSize={matches ? 18 : "5vw"}
       fontWeight="bold"
       style={{ height: matches ? "4.5em" : "unset" }}
     >
       {title}
     </Text>
     <p
       className={clsx(matches ?classes.text : classes.textMobile, {
         [classes.collapsed]: collapsable && !expand,
       })}
     >
       {content}
     </p>
   </div>
 </div>
 </div>}</>
   
  );
}

export default Service;
