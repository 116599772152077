import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import background1 from "../../images/Home/Carousel/HomeQuote/1.webp";
import background2 from "../../images/Home/Carousel/HomeQuote/2.webp";
import background3 from "../../images/Home/Carousel/HomeQuote/3.webp";
import background4 from "../../images/Home/Carousel/HomeQuote/4.webp";
import background5 from "../../images/Home/Carousel/HomeQuote/5.webp";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../Text/Text";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
const quotes = [
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      "SYB’s role as a switching and payment aggregator has been greatly
      beneficial to our firm in terms of our synergy and cooperation with
      fintech companies and banks across Indonesia, as SYB has enabled the
      expansion of our payment network to be effectuated in an exceptionally
      effective and efficient manner.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Teguh Budi Ismanto, Senior Executive Vice President of PT. Pegadaian
      (Persero)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “The World Bank noted that PT Perusahaan Listrik Negara (Persero) (PLN)
      needed to accelerate the Account Receivables ... It would be great if
      PLN's A/R could be trimmed [and made] shorter. With SYB's pioneering idea,
      this is now highly possible.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – M. Mochtar Wirjosaputro during his tenancy as the Director of Enterprise
      Development of the Directorate General of Electricity & Energy Development
      (1989-1993) (An extract from his autobiography entitled “Nrimo Ing
      Pandum”)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “Cooperation with SYB began as early as the year 2004, and now SYB is one
      of OTO Group's strategic partners in developing digital payment services …
      together with SYB, OTO Group continues to expand our digital/online
      payment service network, and has successfully increased the ratio of our
      non-cash payments. Lastly, during the pandemic at the end of 2020, OTO
      Group together with SYB, successfully implemented a digital payment system
      via GoTagihan, whose transaction trend continues to surge exponentially.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Kemal Fajar, Chief Information Officer of PT Summit Oto Finance and PT
      Oto Multiartha
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      "As of today IMFI's cooperation with SYB has stood for more than 10 years.
      PT SYB supported us in developing an array of non-cash payment channels
      for our debtors to pay their instalments. The innovation and solutions
      proposed to fulfil our debtor's needs has helped buttress IMFI's progress.
      SYB's service has also brought forth greater ease for our debtors in our
      numerous branches spread across various regions in Indonesia. SYB's
      support in advancing our business and their team’s agility in grappling
      with the issues that arose, has certainly earned our unanimous
      approbation. We hope for SYB's continued success in the future.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Sifra Viona Tjahjono, Director of PT Indomobil Finance Indonesia (IMFI)
    </Text>
  </div>,
  <div>
    <Text style={{color: "#444444", fontWeight: 500}}>
      “SYB has enabled us to develop a consumer instalment payment service
      across various marketplaces, which is both secure and trustworthy.”
    </Text>
    <Text style={{color: "#444444", fontWeight: 500}}>
      – Felix Priscellius, Head of the IT Division at PT Wahana Ottomitra
      Multiartha Tbk
    </Text>
  </div>,
];

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "rgba(0,0,0,0.6)",
    height: 750,
    position: "absolute",
    left: "10vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "50px",
  },
  carouselSlide1: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide2: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide3: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide4: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background4})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide5: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background5})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainSection: {
    backgroundColor: "#00004D",
    margin: 0,
    color: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textLarge: {
    fontFamily: "Roboto",
    fontSize: 80,
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    margin: 0,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
  },
});

function HomeQuoteCarousel({ height, transitionTime = 1500, interval = 6000 }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const indicatorStyles = {
    display: "inline-block",
    marginRight: "10px",
    color: "#fff",
    cursor: "pointer",
    height: 10,
    width: 10,
    zIndex: 10,
    // marginRight: 8,
    borderRadius: "50%",
    "-webkit-transition": "background-color 0.6s ease",
    transition: "background-color 0.6s ease",
    border: "2px solid #141413",
    transform: matches ? "translateX(200px) translateY(-50px)" : "translateX(100px) translateY(-175px)",
  };

  const imageClasses = [
    classes.carouselSlide1,
    classes.carouselSlide2,
    classes.carouselSlide3,
    classes.carouselSlide4,
    classes.carouselSlide5,
  ];



  return (
    <Carousel
      autoPlay
      swipeable={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      showIndicators={true}
      transitionTime={transitionTime}
      interval={interval}
      renderIndicator={(onClickHandler, isSelected, index, label) => (
        <li
          style={{
            ...indicatorStyles,
            background: isSelected ? "#141413" : "transparent",
            outline: isSelected ? "1px solid #141413" : "none",
          }}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
        />
      )}
    >
      {quotes.map((quote, index) => (
         <>{matches ? 
          <div style={{ width: "100vw", display: "flex" }}>
            <div style={{ width: "50%" }}>
              <section className={imageClasses[index]} style={{ height }} />
             </div>
              <div
                style={{
                  width: "50%",
                  backgroundColor: "#FFFFFF",
                  margin: 0,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <div style={{ width: matches ? 500 : 300, height: matches ? "unset" : 595, textAlign: matches ? "unset" : "left", color: "#1B297C" }}>
                  <div>{quote}</div>
                </div>
              </div>
            </div> : 
            <><div style={{ width: "100%" }}>
            <section className={imageClasses[index]} style={{ height }} />
          </div>
          <div style={{ width: "100vw", display: "flex" }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  margin: 0,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  marginBottom: 95
                }}
              >
                <div style={{ width: "100%", height: matches ? "unset" : 690, textAlign: matches ? "unset" : "left", color: "#1B297C" }}>
                  <div style={{margin : matches ? "unset" : 30, fontSize : matches ? "unset" : "4vw"}}>{quote}</div>
                </div>
              </div>
            </div></> }</>
      ))}
    </Carousel>
  );
}

export default HomeQuoteCarousel;
